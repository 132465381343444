.cart-product {
  position: relative;
  line-height: 1.2;

  @include breakpoint-m-max {
    display: block;
  }

  tr {
    @include breakpoint-m-max {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: $spacing-l 0;
    }
  }

  td {
    position: relative;
    padding: 0;

    @include breakpoint-m {
      min-width: 100px;
    }
  }
}

td.cart-product-image {
  padding: $spacing-m 0;
  width: 40%;

  @include breakpoint-s {
    width: 30%;
  }

  @include breakpoint-m {
    width: auto;
    padding: $spacing-s 0;
  }

  > div {
    position: relative;
    width: 100px;
    height: 50px;

    @include breakpoint-s {
      width: 120px;
      height: 80px;
    }

    img {
      max-height: 100%;
    }
  }
}

td.cart-product-name {
  width: 40%;

  @include breakpoint-m-max {
    width: 70%;
    text-align: right;
    padding: 30px 0;
  }

  @include breakpoint-s-max {
    width: 60%;
  }
}

.cart-product-show-details {
  font-weight: normal;
  margin-top: $spacing-m;
}

.cart-product-quantity {
  .cart-product.line-through & {
    text-decoration: line-through;
  }

  @include breakpoint-m-max {
    margin-right: auto;
  }
}

.cart-product-form,
.cart-product-remove {
  button[type='submit'] {
    margin-top: -$spacing-s;
    margin-left: auto;

    @include breakpoint-m-max {
      height: 19px !important;
      width: 19px !important;
    }
  }
}

.cart-product-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 30px;

  p {
    margin: 0 $spacing-m;
    line-height: 1;
  }
}

.cart-product-price {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: flex-end;
  padding-left: $spacing-m;
}

td.cart-product-remove {
  position: absolute;
  top: $spacing-l;
  right: 0;

  @include breakpoint-m {
    position: static;
    padding-left: $spacing-m;
    width: 50px;
    min-width: 0;
  }
}

.cart-product-quantity-label,
.cart-product-unit-price-label,
.cart-product-price-label {
  font-weight: normal;
  color: $medium-gray;

  @include breakpoint-m {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.cart-product-quantity-label {
  text-align: center;
}

.cart-product-unit-price-label,
.cart-product-price-label {
  text-align: right;
}

.cart-product-last-row {
  padding-right: $spacing-l;
}

tr.cart-product-details {
  padding-top: 0;

  @include breakpoint-m-max {
    td {
      display: block;
      width: 100%;
    }
  }
}

.cart-product-details-content {
  display: flex;
  flex-wrap: wrap;
}

.cart-product-detail {
  width: 50%;
  padding-top: $spacing-l;

  &:nth-child(odd) {
    padding-right: $spacing-m;
  }

  @include breakpoint-l {
    width: 33.3%;
    padding-right: $spacing-m;
  }
}

.cart-product-discount {
  margin-top: $spacing-s;
}

.cart-product-tag {
  display: inline-block;
}
