.cta-block {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint-l {
    flex-direction: row;
  }

  &.image-left {
    @include breakpoint-l {
      flex-direction: row-reverse;
    }
  }

  &.theme-compact {
    flex-direction: column-reverse;

    .cta-block-text {
      width: 100%;
      padding: 5%;
    }

    .cta-block-title {
      font-size: 20px;
    }

    &.has-image {
      .cta-block-image {
        width: 100%;
        position: relative;
        padding-top: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &.theme-full {
    width: 100%;

    .cta-block-text {
      padding: 10% $spacing-l;

      @include breakpoint-s {
        padding: 10% $spacing-xl;
      }

      @include breakpoint-m {
        padding: 10% $spacing-xxl;
      }

      @include breakpoint-l {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .cta-block-image {
      @include breakpoint-l {
        padding-top: 0;
        width: 50%;
        flex-grow: 1;
      }
    }

    &.has-image {
      .cta-block-text {
        flex-grow: 1;

        @include breakpoint-l {
          width: 50%;
          flex-grow: 0;
        }

        @include breakpoint-xl {
          width: 40%;
          min-height: 550px;
        }
      }
    }
  }

  &.theme-small {
    @include breakpoint-l {
      flex-direction: column-reverse;
    }

    @include breakpoint-xl {
      flex-direction: row;
    }

    .cta-block-text {
      min-height: 0;
      width: 100%;

      @include breakpoint-xl {
        width: 60%;
        padding: 10% $spacing-xl;
      }
    }

    &.has-image {
      .cta-block-text {
        padding: 10% 40px;
      }

      .cta-block-image {
        padding-top: 50%;
        width: 100%;
        flex-grow: 0;

        @include breakpoint-xl {
          padding-top: 0;
          width: 40%;
          flex-grow: 1;
        }
      }
    }
  }

  &.color-cyan {
    .cta-block-text {
      background-color: $dark-cyan;
    }
  }

  &.color-gray {
    .cta-block-text {
      background-color: $dark-gray;
    }
  }

  &.color-orange {
    .cta-block-text {
      background-color: $orange--alt;
    }
  }

  &.color-cold {
    .cta-block-text {
      background-color: $cold;
      color: black;
    }
  }

  &.color-clinical {
    .cta-block-text {
      background-color: $clinical;
    }
  }

  &.color-blue {
    .cta-block-text {
      background-color: $blue;
    }
  }

  &.color-nude {
    .cta-block-text {
      background-color: $nude;
      color: black;
    }
  }

  &.color-mint {
    .cta-block-text {
      background-color: $mint;
      color: black;
    }
  }

  &.color-purple {
    .cta-block-text {
      background-color: $purple;
    }
  }

  &.color-transparent {
    .cta-block-text {
      background-color: transparent;
      color: black;

      @include breakpoint-l {
        color: white;
      }
    }
  }

  &.image-as-background {
    .cta-block-image {
      @include breakpoint-l {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-top: 0;
      }
    }
  }
}

.cta-block-text {
  position: relative;
  z-index: 2;
  width: 100%;
  color: white;
}

.cta-block-image {
  position: relative;
  padding-top: 50%;
}

.cta-block-title {
  margin: 0;
}

.cta-block-links {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: $spacing-xl 0 0;

  > li:not(:last-child) {
    margin-right: $spacing-m;
    margin-bottom: $spacing-m;
  }
}
