.global-search {
  position: relative;
}

.global-search-form {
  padding: $spacing-l 0;
  border-bottom: 1px solid $border-gray;
}

.global-search-input {
  max-width: 450px;
  margin: 0 auto;
}

.global-search-label-text {
  @include visually-hidden;
}

.global-search-results {
  position: relative;
  background-color: $light-cyan;
}

.global-search-results-content {
  min-height: 150px;
  padding: $spacing-l 0 $spacing-xl;
}

.global-search-results-header {
  margin-top: $spacing-l;

  span {
    margin-right: $spacing-m;
  }
}

.global-search-results-products {
  margin-top: $spacing-l;
}

.global-search-results-footer {
  margin-top: $spacing-l;
}

.global-search-articles {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  margin-top: $spacing-l;

  li {
    &:not(:first-child) {
      margin-top: $spacing-l;
    }
  }
}

.global-search-article {
  display: flex;
  color: inherit;

  img {
    flex-shrink: 0;
    width: 90px;
    height: auto;
    margin-right: $spacing-m;
  }
}

.global-search-empty-results {
  margin-top: $spacing-l;
}
