.my-store-banner {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: $spacing-xl 0;
  padding: $spacing-xl $spacing-l;
  background-color: $light-cyan;

  @include breakpoint-m {
    margin: $spacing-xl auto;
  }

  h2 {
    width: 100%;
    margin: 0;

    @include breakpoint-m {
      text-align: center;
    }
  }
}

.my-store-banner-content {
  margin-top: $spacing-l;

  @include breakpoint-m {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.my-store-banner-link {
  margin-top: $spacing-l;

  @include breakpoint-m {
    max-width: 400px;
  }
}

.my-store-banner-text {
  @include remove-list-styles;
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-xl;
  max-width: 800px;

  li {
    display: flex;
    align-items: baseline;
    width: 100%;

    &:not(:first-child) {
      margin-top: $spacing-s;
    }

    @include breakpoint-m {
      width: 50%;
      padding-right: $spacing-xl;

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.my-store-banner-icon {
  display: block;
  color: $dark-cyan;
  margin-right: $spacing-m;
}
