.personal-details {
  margin: 0;

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt {
    margin-top: $spacing-l;

    &:first-child {
      margin-top: 0;
    }
  }
}
