.teaser-expander {
  position: relative;
}

.teaser-expander-content {
  position: relative;
  min-height: 150px;
  visibility: visible !important;

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: 150px;
    background-image: linear-gradient(
      0deg,
      rgba(white, 0.99),
      rgba(white, 0.01)
    );
    opacity: 1;
    transition: opacity 0.3s;
  }

  .teaser-expander.is-expanded & {
    &:before {
      opacity: 0;
    }
  }

  .teaser-expander.theme-cyan & {
    &:before {
      background-image: linear-gradient(
        0deg,
        rgba($light-cyan, 0.99),
        rgba($light-cyan, 0.01)
      );
    }
  }
}

.teaser-expander-clicker {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.teaser-expander-button {
  margin-top: $spacing-l;
}
