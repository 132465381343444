.embed-video {
  position: relative;
  width: 100%;
  padding-top: (9 / 16) * 100%;
  background-color: black;
}

.embed-video-player {
  &--youtube {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}
