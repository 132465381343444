.assistive-drawer {
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 10;
  display: block;
  overflow: hidden;
  transform: translateY(-100%);

  &--open {
    transform: none;
    height: auto;
    overflow: visible;
  }

  &__content {
    background-color: $dark-cyan;
    color: white;
    text-align: center;
    padding: $spacing-m;
  }
}
