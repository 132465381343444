.shape-link {
  $self: &;
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: $medium-dark-gray;
  text-decoration: none;
  margin-bottom: 15px;

  &-text {
    justify-self: flex-end;
    width: 100%;
    text-align: left;
    overflow: hidden;
  }

  &-image {
    flex-shrink: 0;
    position: relative;
    height: 40px;
    width: 80px;
    margin-right: $spacing-m;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
      max-height: 100%;
      height: auto;
      width: auto;
      transition: all 0.1s;

      #{$self}:hover &,
      #{$self}:focus-visible & {
        transform: scale(1.08);
      }
    }
  }
}