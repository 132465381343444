.brand-page-text-content {
  @include responsive-margin($spacing-xl, false, $spacing-xl);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.brand-page-ingress {
  &:not(:only-child) {
    margin-bottom: $spacing-xl;
  }
}

.brand-page-blocks {
  @include responsive-margin(60px, false, $spacing-xxl);
}

.brand-page-links {
  @include remove-list-styles;
  @include responsive-margin($spacing-xl, false, 60px);
  display: flex;
  flex-wrap: wrap;

  > li {
    margin-right: $spacing-xl;
  }
}
