.appointment-booking-result-agree {
  position: relative;
  padding: 10px 45px 11px 41px;
  border: 1px solid transparent;
  cursor: default;

  &:empty {
    display: none;
  }

  &__checkmark-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: #007a7d;
    padding: 0 12px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #2c2c2c;
  }
}
