.rich-text {
  /*
  If rich text is used inside a component-list, we remove its outer margins to avoid
  stacking margins and spacing issues between component list items.
   */
  .component-list-item & {
    margin-bottom: 0;
    margin-top: 0;
  }

  &.text-centered {
    max-width: 50em;
    margin-left: auto;
    margin-right: auto;
  }

  &.bottom-margin {
    margin-bottom: $spacing-xl;
  }

  &.theme-within-collapsable {
    ul,
    ol {
      margin: $spacing-m 0;
    }

    ul li{
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  &.theme-big {
    @include responsive-font-size(20px, 27px);
  }

  &.theme-compact-white {
    font-size: 0.9rem;
    color: white;
    line-height: 1.3;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    p,
    ul,
    ol {
      margin: $spacing-m 0;
      line-height: 1.3;
    }

    h2,
    h3,
    h4 {
      margin-top: $spacing-m;
      margin-bottom: $spacing-m;
    }
  }

  &.theme-checkmarks {
    margin-bottom: 0;

    ul {
      margin: 0;
      list-style: none;
      padding: 0 0 0 1.7rem;
    }

    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: url(../../assets/images/checkmark--clinical.svg) no-repeat
          transparent;
        height: 11px;
        width: 12px;
        top: 5px;
        left: -27px;
      }
    }
  }

  p {
    line-height: 1.6;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  ul,
  ol {
    margin: $spacing-l 0;
  }

  a {
    color: $dark-cyan;

    &:hover {
      color: darken($dark-cyan, 5);
      text-decoration: none;
    }
  }

  ul {
    margin: $spacing-xl 0;
    list-style: disc;
  }

  li {
    line-height: 1.3;

    &:not(:first-child) {
      margin-top: $spacing-m;
    }
  }

  h2 {
    @include responsive-font-size(22px, false, 27px);
    line-height: 1.4;
    font-weight: bold;
  }

  h3 {
    @include responsive-font-size(20px, false, 24px);
    letter-spacing: 0.0125em;
    line-height: 1.4;
    font-weight: bold;
  }

  h4 {
    @include responsive-font-size(18px, false, 21px);
    letter-spacing: 0.0125em;
    line-height: 1.45;
    font-weight: bold;
  }

  h2,
  h3,
  h4,
  h5 {
    margin: ($spacing-xl + $spacing-l) 0 ($spacing-m + $spacing-s);

    + p {
      margin-top: $spacing-m + $spacing-s;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  table {
    width: 100%;
    margin: $spacing-xl 0;
    border-collapse: collapse;
    border-bottom: 1px solid $border-gray;

    @include breakpoint-m {
      margin: $spacing-xl 0 ($spacing-xl + $spacing-l);
    }

    thead {
      text-align: left;

      th {
        border-bottom: 1px solid $border-gray;
        padding-bottom: $spacing-m;
        font-weight: normal;
      }
    }

    tbody {
      tr:first-child td {
        padding-top: $spacing-l;
      }

      tr:last-child td {
        padding-bottom: $spacing-l;
      }

      td {
        padding-top: $spacing-m;
      }
    }
  }

  &--in-magazine-footer{
    p,
    ul,
    ol {
      @include responsive-font-size(14px, 16px);
      line-height: 1.4;
      margin: 18px 0;
    }
  }
}
