.appointment-booking-service-accordion {
  margin: 0 0 30px;

  & > button {
    margin-bottom: 0;
  }

  & > button:hover .appointment-booking-service-accordion__header {
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border-color: #007a7d;
    box-shadow: none;
  }

  &__header {
    position: relative;
    display: flex;
    padding-top: 8px;
    padding-right: 0;
    padding-bottom: 10px;
    padding-left: 0;
    border: 1px solid #78b9bb;
    border-radius: 2px;

    @include breakpoint-im {
      padding-top: 13px;
      padding-bottom: 15px;
    }
  }

  .is-active .appointment-booking-service-accordion__header {
    border-color: #007a7d;
  }

  &__header-title {
    flex: 1;
  }

  &__header-text {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    padding: 4px 0 0 !important;
    border-bottom: 4px solid transparent !important;

    @include breakpoint-xs {
      font-size: 18px !important;
      line-height: 25px;
    }
  }

  &__header-icon {

    &--right {
      width: 50px;

      @include breakpoint-im {
        width: 70px;
      }

      .icon {
        width: 100%;
        height: 8px;
        color: #707070;
        transition: all .3s ease;
      }
    }

    &--left {
      width: 60px;
      color: #007a7d;
      padding-right: 10px;
      padding-left: 18px;
      transform: none !important;

      @include breakpoint-im {
        width: 62px;
        padding-left: 20px;
      }

      .icon {
        width: 100%;
        height: auto;
      }
    }
  }

  &:hover, .is-active {
    .appointment-booking-service-accordion__header-icon--right .icon {
      color: #000;
    }
  }

  &__header-decoration {
    position: absolute;
    right: 5px;
    bottom: -6px;
    left: 5px;
    height: 5px;
    border: solid #c0ddde;
    border-width: 0 1px 1px 1px;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 5px;
      border: solid #c0ddde;
      border-width: 0 1px 1px 1px;
    }

    &:before {
      right: 5px;
      left: 5px;
      margin-top: 5px;
    }

    &:after {
      right: 10px;
      left: 10px;
      margin-top: 10px;
    }
  }

  .is-active .appointment-booking-service-accordion__header-decoration {
    display: none;
  }

  &__inner {
    background-color: #dbf3f4;
    padding: 20px 10px 10px;

    @include breakpoint-im {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__content {
    background-color: transparent;
    padding: 0;
    border-bottom: 20px solid transparent;
  }

  &__content-inner {
    position: relative;
    background-color: #f1f9f9;
    padding: 10px;
    border: 1px solid #dbe7e7;
    border-radius: 2px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      border-color: #007a7d;
    }

    @include breakpoint-im {
      padding: 10px 15px;
    }
  }

  &__label {
    position: absolute;
    top: -8px;
    right: 0;
    left: 0;
    text-align: center;

    &:empty {
      display: none;
    }
  }

  &__label-inner {
    display: inline-block;
    vertical-align: top;
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    color: #000;
    background-color: #f1f9f9;
    padding: 0 15px;
    border: 1px solid #dbe7e7;
  }

  &__content-header {
    padding-bottom: 4px;

    @include breakpoint-xs {
      display: flex;
    }
  }

  &__name {
    flex: 1;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #000;

    @include breakpoint-xs {
      line-height: 25px;
    }
  }

  &__additional-info {
    display: inline-block;
    vertical-align: top;
    max-width: 164px;
    line-height: 19px;

    @include breakpoint-xs {
      padding-left: 10px;
    }
  }

  &__time {
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #707070;

    @include breakpoint-xs {
      line-height: 25px;
    }
  }
  
  &__price {
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #007a7d;

    @include breakpoint-xs {
      line-height: 25px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
    color: #707070;
    margin: 0 !important;

    @include breakpoint-xs {
      font-size: 18px;
      line-height: 25px;
    }

    p {
      line-height: inherit !important;
    }
  }
}
