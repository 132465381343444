.tiny-cta-block {
  $self: &;

  &--framed {
    #{$self}__content {
      padding: 1rem;
    }

    #{$self}__text {
      padding: 0;
    }
  }

  &--mint {
    color: $clinical;
    background-color: $mint;
  }

  &--blue {
    color: white;
    background-color: $blue;
  }

  &--cyan {
    color: white;
    background-color: $dark-cyan;
  }

  &--orange {
    color: white;
    background-color: $orange--alt;
  }

  &--cold {
    background-color: $cold;
  }

  &--clinical {
    color: white;
    background-color: $clinical;
  }

  &--nude {
    background-color: $nude;
  }

  &--purple {
    color: white;
    background-color: $purple;
  }

  &--gray {
    color: white;
    background-color: $dark-gray;
  }

  &--transparent {
    color: black;
    background-color: transparent;
  }

  &--horizontal {
    #{$self}__content {
      @include breakpoint-m {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    #{$self}__text {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    #{$self}__image {
      flex: 0 0 330px;
      order: 1;
      min-height: 330px;
    }
  }

  &--vertical {
    #{$self}__text {
      padding: 1rem;
    }

    #{$self}__content {
      display: flex;
      flex-direction: column;
    }

    #{$self}__image {
      height: 330px;
    }
  }

  &__image {
    flex: 0 0 auto;
    position: relative;
  }

  &__text {
    flex: 0 1 auto;

    > * {
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 1rem;
      }
    }
  }

  &__title,
  &__lead {
    margin: 0;
  }
}
