.reserve-in-store{
  $self: &;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint-m {
    padding: 50px;
  }

  &__heading{
    text-align: center;
    @include responsive-font-size(18px, false, 21px);
    line-height: 30px;
    font-weight: normal;
    text-transform: uppercase;
    color: $medium-gray;
    margin: 0 0 8px;
    letter-spacing: 1px;
    padding: 0 15px;
  }

  &__product-name{
    text-align: center;
    font-size: 27px;
    line-height: 1;
    font-weight: bold;
  }
  

  &__modal{
    position: static !important;
    width: calc(100% - 20px);
    max-width: 944px;
    height: 100%;
    display: flex;

    &-content{
      background: #fff;
      height: calc(100% - 20px);
      margin-top: 10px;
      width: 100%;

      @media screen and (min-height: 800px) {
        height: calc(100% - 150px);
        margin-top: 75px;
      }

      @media screen and (min-height: 1000px) {
        height: calc(100% - 200px);
        margin-top: 100px;
      }
    }

    &-close{
      top: 30px;
      @include breakpoint-m {
        top: 50px;
        right: 50px;
      }
    }
  }

  &__results-heading{
    @include responsive-font-size(20px, false, 24px);
    line-height: 30px;
    margin: 0 0 20px;

    @include breakpoint-m {
      margin: 0 0 40px;
    }
  }

  &__form-wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px 0 30px;
    
    @include breakpoint-m {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 60px;
      padding-bottom: 40px;
    }
  }

  &__form{
    align-self: flex-start;
    width: 100%;
    padding-top: 30px;
    
    @include breakpoint-m {
      flex: 0 0 57%;
      margin-left: 20px;
      padding-top: 0;
    }
  }

  &__stores{
    border-top: 1px solid black;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 80vh;

    @include breakpoint-m {
      padding-right: 20px;
      min-height: 280px;

      .touchevents &{
        padding-right: 0;
      }
    }
  }

  &__modal .modal-background{
    min-height: 100% !important;
  }
}