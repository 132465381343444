.video {
  video {
    width: 100%;
  }

  &.theme-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
