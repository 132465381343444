.product-selling-points {
  position: relative;
  list-style: none;
  padding-left: 30px;
  margin: 0;
  text-align: left;

  li ~ li {
    margin-top: $spacing-l;
  }

  svg {
    position: absolute;
    left: 0;
    margin-top: $spacing-s;
  }
}
