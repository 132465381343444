.footer-section {
  background-color: $dark-gray;
  padding: $spacing-xl 0;
  color: white;

  + .footer-section {
    background-color: white;
    padding-top: 30px;
    color: inherit;

    .footer-row:not(:last-child) {
      border-color: $border-gray;
    }
  }
}

.footer-row {
  &:not(:first-child) {
    margin-top: $spacing-l + $spacing-m;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(white, 0.1);
    padding-bottom: $spacing-l + $spacing-m;
  }

  &:first-child {
    @include breakpoint-l-max {
      margin-top: 0;
    }
  }

  @include breakpoint-m {
    display: flex;
  }
}

.footer-column {
  margin-top: ($spacing-l + $spacing-m);

  @include breakpoint-m {
    width: 33.3%;
    padding-right: $spacing-l;
    margin-top: 0;
  }

  h3 {
    text-transform: uppercase;
    margin: 0 0 $spacing-m;
    font-weight: 300;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  p {
    &:first-child {
      margin-top: 0;
    }
  }
}

.footer-image-link {
  display: flex;
  align-items: flex-start;

  img {
    position: relative;
    top: 0.5em;
    transform: translateY(-50%);
    max-height: $spacing-xl;
    width: auto;
    margin-right: $spacing-l;
  }
}

.footer-newsletter {
  display: inline-block;
}

.footer-newsletter-button {
  position: relative;
  z-index: 2;
  display: block !important;
  height: 100%;
  padding: $spacing-s $spacing-m 0;
}

.footer-newsletter-button-container {
  position: relative;
  flex-shrink: 0;
}

.footer-newsletter-input {
  width: 100%;
  height: 100%;
  margin-right: -$spacing-s;
}

.footer-newsletter-form-body {
  display: flex;
}

.footer-image-lists {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $border-gray;

  @include breakpoint-l {
    padding-bottom: 30px;
  }
}

.footer-social-media-list,
.footer-image-list {
  margin-bottom: $spacing-xl;
  margin-right: $spacing-xl;

  @include breakpoint-l {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-right: 0;
  }

  p {
    margin: 0;

    &:after {
      content: ':';
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: $spacing-s;

    @include breakpoint-l {
      margin-top: 0;
      margin-left: $spacing-l;
    }
  }

  li {
    margin-right: $spacing-m;
  }
}

.footer-image-list {
  @include breakpoint-l {
    margin-right: $spacing-xl;
  }
}

.footer-social-media-list {
  @include breakpoint-l {
    margin-left: auto;
  }
}

.footer-image-list-image {
  max-width: 80px;
}

.footer-newsletter-terms-link {
  margin-top: $spacing-m;
}

.footer-link-list {
  li:not(:first-child) {
    margin-top: $spacing-m;
  }
}

.footer-link-list-link {
  svg {
    color: $orange;
  }
}

.footer-texts {
  padding-top: 30px;
  color: $medium-dark-gray;

  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  > * {
    margin: 0;
  }
}
