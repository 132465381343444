.text-with-bg-color-block {
    $self: &;
    padding: $spacing-xl;
    width: 100%;

    @include breakpoint-l {
        margin: 0 auto;
    }

    #{$self}__content {
        word-break: break-word;
    }

    &--white {
        color: black;
        background-color: none;
        padding: 0;
    }

    &--mint {
        color: $dark-green;
        background-color: $light-cyan;
    }
    
    &--orange {
        color: $dark-orange;
        background-color: $light-orange;
    }

    &--blue {
        color: $blue;
        background-color: $light-blue;
    }

    &--purple {
        color: $purple;
        background-color: $light-purple;
    }
}
