.newsletter-form {
  display: block;
  width: 100%;
  $self: &;

  &__button {
    position: relative;
    z-index: 2;
    display: block !important;
    height: 100%;
    padding: $spacing-s $spacing-m 0;
  }

  &__body {
    display: flex;
  }

  &__button-container {
    position: relative;
    flex-shrink: 0;
  }

  &__input {
    width: 100%;
    height: 100%;
    margin-right: -$spacing-s;
  }

  &__terms-link {
    margin-top: $spacing-l;
  }




  &--mint {
    background-color: $mint;
  }

  &--blue {
    background-color: $blue;
  }

  &--cyan {
    background-color: $dark-cyan;
  }

  &--orange {
    background-color: $orange--alt;
  }

  &--cold {
    background-color: $cold;
  }

  &--clinical {

    background-color: $clinical;
  }

  &--nude {
    background-color: $nude;
  }

  &--purple {
    background-color: $purple;
  }

  &--gray {
    background-color: $dark-gray;
  }

  &--mint,
  &--cold,
  &--nude {
    #{$self}__terms-link {
      color: black;
    }
  }
  &--clinical,
  &--cyan {
    #{$self}__button {
      background-color: $dark-gray;
    }
  }
}