.personal-information-group {
  width: 100%;
  margin-top: $spacing-xl;
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacing-m;

  &--centered {
    align-items: center;
  }

  h3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $spacing-m;
  }

  .personal-information-login & {
    margin-top: 0;
  }
}

.personal-information-input {
  margin-left: $spacing-m;
  margin-top: $spacing-m;
  width: calc(100% - #{$spacing-m});

  &:first-child {
    margin-top: 0;
  }

  @include breakpoint-m {
    width: calc(50% - #{$spacing-m});
  }

  &--break {
    @include breakpoint-m {
      margin-right: 1px; //a cheeky little cheat to force flex to break the next block onto a new line
    }
  }

  &--third {
    width: calc(50% - #{$spacing-m});

    @include breakpoint-m {
      width: calc(33.33333% - #{$spacing-m});
    }
  }

  &--fourth {
    width: calc(50% - #{$spacing-m});

    @include breakpoint-m {
      width: calc(25% - #{$spacing-m});
    }
  }

  &--full {
    width: calc(100% - #{$spacing-m});

    @include breakpoint-m {
    }
  }
}

.personal-information-collapse {
  width: 100%;
  margin-top: $spacing-xl;

  .personal-information-group {
    padding-top: $spacing-l;
    padding-bottom: $spacing-xl + $spacing-l;
  }

  .personal-information-group,
  + .personal-information-group {
    margin-top: 0;
  }
}

.personal-information-link {
  margin: 0 0 0 $spacing-l;
}

.personal-information-login {
  max-width: 600px;
  margin-bottom: $spacing-xxl;

  @include breakpoint-l {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    max-width: none;
  }

  h3 {
    width: 100%;
    margin: 0 auto;
  }
}

.personal-information-login-submit {
  margin-bottom: $spacing-s;
  margin-top: $spacing-l;
  width: 100%;

  @include breakpoint-s {
    width: calc(50% - #{$spacing-m});
  }

  @include breakpoint-l {
    margin-left: $spacing-l;
    width: auto;
  }
}

.personal-information-forgot-password {
  width: 100%;
  margin-top: $spacing-l;
}

.personal-information-form-links {
  width: 100%;
  margin-bottom: $spacing-l;

  a {
    margin-right: $spacing-l;

    &:last-child {
      margin-right: 0;
    }
  }
}
