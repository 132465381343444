.appointment-booking-form {
  text-align: right;
  padding-top: 70px;
  padding-bottom: 90px;

  @include breakpoint-im {
    max-width: 384px;
    padding-top: 20px;
    padding-bottom: 15px;
    margin: 0 auto;

  }

  .text-input {
    text-align: left;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2c2c2c;
      margin-bottom: 0;
    }

    input {
      background-color: transparent;
      padding: 12px 16px;
      border: 1px solid #bfdfe0;
      height: auto;
    }

    input:focus {
      background-color: #fff;
    }

    input:hover,
    input:focus {
      @include input-placeholder {
        color: #2c2c2c !important;
      }
    }

    &.has-error input {
      color: #2c2c2c;
      background-color: transparent;
      border-color: #de6a6a;
      height: auto;

      @include input-placeholder {
        color: #666;
      }
    }

    &.is-filled input {
      background-color: #fff;
    }
  }

  .text-input-label-error {
    color: #de6a6a;
  }

  &__text-attention {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: left;
    color: #007a7d;
    margin: 0 0 65px;

    @include breakpoint-im {
      margin: 0 0 30px;
    }
  }

  .form-submit {
    margin-top: 10px;

    @include breakpoint-im {
      display: block;
      width: 100%;
      height: auto !important;
      max-width: 384px;
      font-size: 21px !important;
      line-height: 24px !important;
      padding-top: 21px !important;
      padding-bottom: 25px !important;
      box-sizing: border-box !important;
      margin-top: 25px;
    }

    span {
      @include breakpoint-im {
        line-height: inherit;
      }
    }
  }
}
