.skip-to-main-button {
  &__link {
    position: absolute;
    width: 100%;
    z-index: 10;
    display: block;
    background-color: $dark-cyan;
    color: white;
    text-align: center;
    padding: $spacing-m;
    transform: translateY(-100%);

    &:focus {
      transform: none;
    }
  }
}
