.text {
  font-size: inherit;
  font-weight: normal;

  &.theme-big {
    @include responsive-font-size(20px, false, 27px);
    line-height: 1.1;
  }

  &.theme-bold {
    font-weight: bold;
  }

  &.theme-big-and-bold {
    @include responsive-font-size(18px, false, 20px);
    font-weight: bold;
    line-height: 1.1;
  }

  &.theme-bold-uppercase {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1;
  }

  &.theme-bold-uppercase-small {
    @include responsive-font-size(14px, false, 16px);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1;
  }

  &.theme-filled {
    @include responsive-font-size(14px, false, 16px);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    color: white;
    background-color: $orange--alt;
    padding: 5px 8px;
  }

  &.theme-extra-small {
    @include responsive-font-size(12px, false, 14px);
  }

  &.theme-h1 {
    @include responsive-font-size(40px, 50px, false, 62px);
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.15;
    letter-spacing: 0.0345em;
  }

  &.theme-h2 {
    @include responsive-font-size(22px, false, 27px);
    line-height: 1.4;
    font-weight: bold;
  }

  &.theme-h3 {
    @include responsive-font-size(20px, false, 24px);
    letter-spacing: 0.0125em;
    line-height: 1.4;
    font-weight: bold;
  }

  &.theme-small {
    @include responsive-font-size(14px, false, 16px);
  }

  &.theme-uppercase {
    @include responsive-font-size(18px, false, 21px);
    text-transform: uppercase;
    line-height: 1.1;
  }

  &.theme-uppercase-big {
    @include responsive-font-size(20px, false, 27px);
    text-transform: uppercase;
    line-height: 1.1;
    font-weight: 300;
    letter-spacing: 0.15em;
  }

  &.theme-uppercase-large {
    @include responsive-font-size(20px, false, 24px);
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
  }

  &.theme-uppercase-huge {
    @include responsive-font-size(20px, 24px, false, 32px);
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
  }

  &.theme-uppercase-jumbo {
    @include responsive-font-size(30px, 38px, false, 48px);
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
  }

  &.theme-uppercase-small {
    @include responsive-font-size(14px, false, 16px);
    text-transform: uppercase;
    line-height: 1.1;
  }
}
