.metadata {
  max-width: 500px;

  h2 {
    margin: 0;
  }
}

.metadata-description-list {
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: repeat(100, auto);
  margin: $spacing-xl 0;

  dt {
    grid-column: 1;
  }

  dd {
    grid-column: 2;
    margin: 0;
    padding-left: $spacing-m;
  }

  @for $i from 1 to 20 {
    dt,
    dd {
      &:nth-of-type(#{$i}) {
        grid-row: $i;
      }
    }
  }
}

.metadata-desc-tag,
.metadata-desc {
  display: inline-block;
  margin: 0;
  font-weight: 300;
  vertical-align: top;
}

.metadata-button-wrapper {
  width: 100%;
  text-align: center;

  @include breakpoint-s {
    width: auto;
    text-align: left;
  }
}

.metadata-button {
  margin-top: $spacing-l;
}
