.appointment-booking-assistant-accordion {
  position: relative;
  padding-top: 20px;

  & > button {
    margin-bottom: 0;

    & + div {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 1;
    }
  }

  & > button:hover .appointment-booking-assistant-accordion__header {
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border-color: #dbe7e7;
    box-shadow: none;
  }

  .filled .appointment-booking-assistant-accordion__header {
    background-color: #dbf3f4 !important;
    border-color: #dbf3f4 !important;
  }

  &__header {
    position: relative;
    display: flex;
    padding-top: 1px;
    padding-right: 0;
    padding-bottom: 1px;
    padding-left: 0;
    border: 1px solid #dbe7e7;
    border-radius: 2px;
  }

  &__header-image-holder {
    width: 20px;
    height: 20px;
    margin: 0 4px 0 11px;
  }

  &__header-title {
    flex: 1;
  }

  &__header-text {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px;
    text-align: left;
    color: #000;
    padding-top: 7px;
    padding-right: 11px;
    padding-bottom: 7px;
    padding-left: 11px;
    border: none;
  }

  &__header-icon {

    &--right {
      width: 50px;

      .icon {
        width: 100%;
        height: 8px;
        color: #707070;
        transition: all .3s ease;
      }
    }
  }

  &:hover, .is-active {
    .appointment-booking-assistant-accordion__header-icon--right .icon {
      color: #000;
    }
  }

  &__cancel {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-transform: uppercase;
    color: #666;
    padding-top: 7px;
    padding-right: 15px;
    padding-bottom: 7px;
    padding-left: 15px;
    transition: all .25s ease;
  }

  &:hover, .is-active {
    .appointment-booking-assistant-accordion__cancel {
      color: #000;
    }
  }

  &__inner {
    background-color: #f1f9f9;
    border: solid #dbe7e7;
    border-width: 0 1px 1px;

    & > div:last-child .appointment-booking-assistant-accordion__content-inner {
      border: none;
    }
  }

  &__content {
    background-color: transparent;
    padding: 0 13px;
    border: none;
    cursor: pointer;
    transition: background-color .25s ease;

    &:hover {
      background-color: #dbe7e7;
    }
  }

  &__content-inner {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 9px 0;
    border-bottom: 1px solid #dbe7e7;
  }

  &__image-holder {
    width: 20px;
    height: 20px;
  }

  &__name {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000;
    padding-left: 10px;
  }
}
