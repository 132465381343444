.my-orders {
  @include default-table;

  th {
    color: $medium-dark-gray;
  }

  td {
    span {
      &:first-child:not(:only-child) {
        @include breakpoint-l {
          display: none;
        }
      }
    }
  }
}

.my-orders-button-container {
  display: flex;
  justify-content: center;
  margin-top: $spacing-xl;
}
