.my-page-layout {
  background-color: $light-cyan;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: $spacing-xxl;
}

.my-page-layout-link {
  margin-top: $spacing-xl;
}

.my-page-layout-header {
  background-color: white;
  padding: $spacing-xl 0;
  text-align: center;

  h1 {
    margin-bottom: 0;

    @include breakpoint-m {
      margin-top: $spacing-xl;
    }
  }

  p {
    margin-top: $spacing-m;
    margin-bottom: 0;
  }
}

.my-page-layout-logout {
  display: inline-block;
  margin-top: $spacing-l;
}

.my-page-layout-content {
  margin-top: $spacing-xl;

  @include breakpoint-m {
    margin-top: $spacing-xxl;
  }
}
