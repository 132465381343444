.glasses-measurement-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.glasses-measurement {
  display: flex;
  flex-direction: column;
  margin: $spacing-l;
  width: calc(50% - #{$spacing-xl});

  img {
    align-self: center;
    max-width: 250px;
  }

  @include breakpoint-l {
    justify-content: space-between;
  }
}

.glasses-measurement-text-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.glasses-measurement-text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: $spacing-m $spacing-m;

  span {
    text-align: center;
    font-weight: 300;
  }
}
