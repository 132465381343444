.breadcrumbs {
  margin-top: $spacing-m;

  @include breakpoint-m {
    margin-top: $spacing-l;
  }

  &--magazine{
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px solid $border-gray;
    @include breakpoint-m {
      padding-top: 30px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    line-height: 1;
    margin-bottom: $spacing-m;

    &:not(:last-child) {
      border-right: 1px solid $border-gray;
      padding-right: $spacing-m;
      margin-right: $spacing-m;
    }
  }
}
