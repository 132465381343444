.text-input {
  width: 100%;
  // min-width: 200px;
  flex-grow: 1;
  position: relative;

  @include breakpoint-l {
    flex-grow: 0;
  }

  &:not(:first-child) {
    margin-top: $spacing-l;
  }

  &.theme-big-text {
    input {
      @include responsive-font-size(18px, false, 21px);
    }
  }

  &.theme-grid {
    @include breakpoint-s {
      margin-left: $spacing-m;
      margin-right: $spacing-m;
    }

    @include breakpoint-m {
      width: calc(50% - #{$spacing-l});

      &:first-child:not(.theme-wide) + .text-input {
        margin-top: 0;
      }
    }
  }

  &.theme-cyan {
    label {
      @include visually-hidden;
    }

    input {
      border-color: $clinical;
      background-color: white;
      border-width: 2px;
    }
  }

  &.theme-hidden-label {
    label {
      @include visually-hidden;
    }
  }

  &.theme-narrow {
    @include breakpoint-s {
      min-width: 0;
      flex-grow: 0;
      width: calc(50% - #{$spacing-l});
    }

    @include breakpoint-m {
      width: 25%;
      min-width: 130px;
    }
  }

  &.theme-dark {
    input {
      border-color: darken($dark-cyan, 5) !important;
      background-color: darken($dark-cyan, 5);
      color: white;

      @include input-placeholder {
        color: white;
        opacity: 1;
      }
    }
  }

  &.theme-bordered {
    input {
      border: 1px solid $border-gray-dark;
    }
  }

  &.theme-wide {
    @include breakpoint-m {
      width: 75%;
    }
  }

  &.has-error {
    input {
      background-color: white;
      border-color: $orange;
      color: $orange;
    }
  }

  label {
    display: block;
    margin: 0;
    line-height: 1;
    margin-bottom: $spacing-m;
  }

  .text-input-label-extra-text{
    display: block;
    clear: both;
  }

  input {
    // NOTE: Do not use font sizes smaller than 16px. That would trigger device zoom on iOS
    font-size: 16px;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $light-gray;
    color: $dark-gray;
    padding: 14px 23px;
    line-height: $spacing-l;
    border: 1px solid transparent;

    @include input-placeholder {
      color: $medium-gray;
      opacity: 1;
    }

    &:disabled {
      background-color: $light-gray;
      border: 1px solid $border-gray;
      color: $medium-gray;
    }
  }
}

.text-input-error {
  color: $orange;
  margin-top: $spacing-s;

  .theme-no-validation & {
    display: none;
  }
}

.text-input-label-error {
  padding-left: 10px;
}

.text-input-password-button {
  @include remove-button-styles;
  position: absolute;
  margin-top: -30px;
  right: $spacing-l;
  color: $cyan;

  span:first-child {
    @include visually-hidden;
  }

  &.is-active .text-input-password-icon:before {
    transform: rotate(-30deg) scaleX(1);
  }
}

.text-input-password-icon {
  position: relative;
  display: block;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    width: 110%;
    height: 2px;
    background-color: currentColor;
    box-shadow: 0 1px 0 $light-gray;
    transform: rotate(-30deg) scaleX(0);
    transform-origin: 100% 100%;
    transition: transform 0.3s $ease-out;
  }

  svg {
    display: block;
  }
}
