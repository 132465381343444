.my-page-section {
  position: relative;
  background-color: white;
  padding: $spacing-l;
  margin-bottom: $spacing-l;
  box-shadow: $box-shadow;

  @include breakpoint-m {
    padding: 60px;
  }
}

.my-page-section-header {
  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  @include breakpoint-l {
    margin-bottom: $spacing-xl;
  }

  a {
    .touchevents & {
      @include breakpoint-m-max {
        margin: $spacing-m 0;
      }
    }

    &:not(:last-of-type) {
      margin-right: $spacing-l;
    }
  }

  h1 {
    margin: 0;
  }

  h2 {
    margin: 0;
    margin-bottom: $spacing-m;
    line-height: 1;
  }

  h1,
  h2 {
    width: 100%;
    margin-bottom: $spacing-m;

    @include breakpoint-l {
      width: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
    margin-right: $spacing-l;
  }
}

.my-page-section-ingress {
  width: 100%;
  margin: $spacing-m 0;
}
