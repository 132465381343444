/**
 * @license
 * MyFonts Webfont Build ID 3845391, 2019-12-09T06:59:09-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: UniversLT-Condensed by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-condensed/
 * Copyright:  Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1987, 1991, 1994, 1998, 2004 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Libra
 * 
 * Webfont: UniversLT-CondensedBold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-bold-condensed/
 * Copyright:  Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1987, 1991, 1994, 1998, 2004 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Libra
 * 
 * Webfont: UniversLTPro-LightCond by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-light-condensed/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-45Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-45-light/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-45LightOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-45-light-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-55Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-55-roman/
 * Copyright: Copyright &#x00A9; 2017 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-55Oblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-55-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-65Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-65-bold/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-65BoldOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-65-bold-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-75Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-75-black/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-75BlackOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-75-black-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-85XBlack by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-85-extra-black/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-85XBlackObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-85-extra-black-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-LightCondObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-light-condensed-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-CondensedObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-condensed-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-BoldCondObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-bold-condensed-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-ThinUltCond by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-thin-ult-cond/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-LightUltCond by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-light-ult-cond/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-UltCond by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-ultra-condensed/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-Ex by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-53-extended/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-ExObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-53-extended-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-BoldExt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-bold-extended/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-BoldExtOblique by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-bold-extended-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-BlackExt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-black-extended/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-BlackExtObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-black-extended-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-XBlackExt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-extra-black-extended/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * Webfont: UniversLTPro-XBlackExtObl by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-extra-black-extended-oblique/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * 
 * 
 * Licensed pageviews: 7,500,000
 * 
 * © 2019 MyFonts Inc
*/

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/3AAD0F_0_0.eot');
  src: url('../assets/fonts/3AAD0F_0_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_0_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_0_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: normal;
  font-style: italic;
  src: url('../assets/fonts/3AAD0F_E_0.eot');
  src: url('../assets/fonts/3AAD0F_E_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_E_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_E_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_E_0.ttf') format('truetype');
}

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: bold;
  font-style: normal;
  src: url('../assets/fonts/3AAD0F_1_0.eot');
  src: url('../assets/fonts/3AAD0F_1_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_1_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_1_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: bold;
  font-style: italic;
  src: url('../assets/fonts/3AAD0F_F_0.eot');
  src: url('../assets/fonts/3AAD0F_F_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_F_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_F_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_F_0.ttf') format('truetype');
}

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: 300;
  font-style: normal;
  src: url('../assets/fonts/3AAD0F_2_0.eot');
  src: url('../assets/fonts/3AAD0F_2_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_2_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_2_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'UniversLT-Condensed';
  font-weight: 300;
  font-style: italic;
  src: url('../assets/fonts/3AAD0F_D_0.eot');
  src: url('../assets/fonts/3AAD0F_D_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/3AAD0F_D_0.woff2') format('woff2'),
    url('../assets/fonts/3AAD0F_D_0.woff') format('woff'),
    url('../assets/fonts/3AAD0F_D_0.ttf') format('truetype');
}
