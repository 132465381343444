.main-nav {
  $header-height-mobile: 115px;
  $header-height-tablet: 140px;

  position: relative;
  z-index: 2;
  background-color: white;

  @include breakpoint-l {
    padding: 15px 0;
    border-top: 1px solid $border-gray;
    box-shadow: inset 0 -1px 0 0 $border-gray;
  }

  @include breakpoint-l-max {
    position: fixed;
    padding-top: 1rem;
    padding-bottom: 2rem;
    left: -500px;
    z-index: 2;
    width: 100%;
    max-width: 500px;
    background-color: white;
    -webkit-overflow-scrolling: touch;
    transition: left 0.8s;
    overflow-y: scroll;
    top: $header-height-tablet;
    height: calc(100vh - #{$header-height-tablet});

    &.is-expanded {
      transform: none;
      left: 0;
    }

    &.before-enter,
    &.leaving {
      opacity: 0;
      transform: translateX(-100%);
    }

    &.entering {
      opacity: 1;
      transform: none;
      transition: transform 0.5s $ease-out, opacity 0.3s;
    }

    &.before-leave {
      transition: transform 0.4s $ease-out, opacity 0.2s 0.2s;
    }
  }

  @include breakpoint-m-max{
    top: $header-height-mobile;
    height: calc(100vh - #{$header-height-mobile});
  }
}

.main-nav-content {
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint-l {
    flex-direction: row;
    position: static;
    align-items: center;
  }

  @include breakpoint-l-max {
    width: 100%;
    padding: 0 4vw;
  }

  @include breakpoint-m-max {
    padding: 0 7.5vw;
  }

  @include breakpoint-s-max {
    padding: 0 5vw;
  }
}

.main-nav-buttons {
  display: flex;
  margin-left: auto;
}

.main-nav-book-appointment {
  display: block;
  margin-left: $spacing-m;

  @include breakpoint-l-max {
    display: none;
  }
}

.main-nav-support-menu {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @include breakpoint-l {
    display: none;
  }

  li {
    margin-right: $spacing-l;
  }

  a {
    text-decoration: none;
  }
}

.main-nav-items {
  @include remove-list-styles;
  margin: $spacing-l 0 400px 0;

  @include breakpoint-l {
    display: flex;
    margin: 0;

    > li {
      display: block;
      margin-right: 30px;
    }
  }
}

.main-nav-link {
  display: block;
  border-bottom: 4px solid transparent;
  padding: 7px 0 3px;

  @include breakpoint-l-max {
    display: none !important;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-l 0 $spacing-m;
    border-bottom: none;
  }

  &:hover,
  &.is-active {
    border-bottom-color: $orange;
  }
}

.main-nav-button {
  width: 100%;
  padding: $spacing-m;
  background-color: $light-cyan;
  margin-bottom: $spacing-s;

  @include breakpoint-l {
    display: none;
  }

  &.is-active {
    svg {
      transform: rotate(-180deg);
    }
  }

  svg {
    display: block;
    transition: transform 0.3s $ease-out;
  }
}

.main-nav-button-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav-button-inner-link {
  flex: 0 1 60%;
  text-align: left;
  padding: 10px;
  text-decoration: none;
  color: inherit;
}

.main-nav-button-inner-button {
  padding: 15px;
  border: 0;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
}

.main-nav-submenu {
  width: 100%;
  background-color: white;

  @include breakpoint-l {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 100%;
  }
}

.main-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.4);

  // tiny-transition effects
  &.before-enter,
  &.leaving {
    opacity: 0;
  }

  &.entering {
    opacity: 1;
  }

  &.entering,
  &.before-leave {
    transition: opacity 0.3s;
  }
}
