.component-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-around;
  margin-top: -40px;
  margin-bottom: 40px;

  @include breakpoint-m {
    margin-left: -$spacing-m;
  }
}
