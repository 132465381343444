.store-map-map {
  width: 100%;
  height: 300px;
  overflow: hidden;

  @include breakpoint-s {
    height: 500px;
  }

  .store-map--mobile & {
    display: none;

    @include breakpoint-m {
      display: block;
    }
  }
}

.store-map-popup {
  min-width: 300px;
  padding: $spacing-m;
  padding-right: 0;
  font-family: $font-stack;
  font-weight: normal;
  color: $dark-gray;

  p {
    margin: 0;
  }
}

.store-map-popup-name {
  display: inline-block;
  margin-bottom: $spacing-m;
}

.store-map-popup-hours {
  margin-top: $spacing-m;
}

.store-map-popup-contact {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-m;
}

.store-map-link {
  .store-map--mobile & {
    @include breakpoint-m {
      display: none;
    }
  }
}
