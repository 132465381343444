.radio {
  input {
    @include visually-hidden;
  }

  label {
    position: relative;
    display: flex;
    flex-grow: 1;

    &:before {
      content: '';
      flex-grow: 0;
      flex-shrink: 0;
      display: inline-block;
      margin-top: -2px;
      height: 20px;
      width: 20px;
      box-shadow: inset 0 0 0 1px black;
      border-radius: 100%;
      margin-right: $spacing-m;
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      margin-top: -2px;
      width: 12px;
      height: 12px;
      background-color: $orange;
      border-radius: 100%;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.3s $ease-out, opacity 0.3s;
    }
  }

  input:checked + label {
    &:after {
      opacity: 1;
      transform: none;
    }
  }

  html:not(.mouse-user) & input:focus + label:before {
    @include focus-outline;
  }
}

.radio-error-message {
  color: $orange;
  padding-left: 30px;
  margin-top: $spacing-s;
}
