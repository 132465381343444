@mixin cf() {
  *zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin focus-outline {
  outline: 3px solid black;
}

@mixin visually-hidden {
  position: absolute;
  left: -999em;
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  overflow: hidden;
}

@mixin remove-list-styles {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin button-common {
  padding: $button-padding;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  justify-content: center; // NOTE: Used when button has icon
  transition: $transition--default;
}

@mixin remove-button-styles {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  font-family: inherit;
}

@mixin responsive-font-size(
  $size-s,
  $size-m: false,
  $size-l: false,
  $size-xl: false
) {
  font-size: $size-s;

  @if ($size-m) {
    @include breakpoint-m {
      font-size: $size-m;
    }
  }

  @if ($size-l) {
    @include breakpoint-l {
      font-size: $size-l;
    }
  }

  @if ($size-xl) {
    @include breakpoint-xl {
      font-size: $size-xl;
    }
  }
}

@mixin responsive-margin(
  $size-s,
  $size-m: false,
  $size-l: false,
  $size-xl: false
) {
  margin-top: $size-s;

  @if ($size-m) {
    @include breakpoint-m {
      margin-top: $size-m;
    }
  }

  @if ($size-l) {
    @include breakpoint-l {
      margin-top: $size-l;
    }
  }

  @if ($size-xl) {
    @include breakpoint-xl {
      margin-top: $size-xl;
    }
  }
}

@mixin non-js-reveal {
  animation: non-js-reveal 0s 2s backwards;
}

@mixin non-js-hide {
  animation: non-js-reveal 0s 2s reverse forwards;
}

@keyframes non-js-reveal {
  from {
    visibility: hidden;
    position: absolute;
  }
  to {
    visibility: visible;
    position: static;
  }
}

// Breakpoints
@mixin breakpoint-xxs {
  @media (min-width: $breakpoint-xxs) {
    @content;
  }
}
@mixin breakpoint-xs {
  @media (min-width: $breakpoint-xs) {
    @content;
  }
}
@mixin breakpoint-s {
  @media (min-width: $breakpoint-s) {
    @content;
  }
}
@mixin breakpoint-im {
  @media (min-width: $breakpoint-im) {
    @content;
  }
}
@mixin breakpoint-m {
  @media (min-width: $breakpoint-m) {
    @content;
  }
}
@mixin breakpoint-l {
  @media (min-width: $breakpoint-l) {
    @content;
  }
}
@mixin breakpoint-xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin breakpoint-xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin breakpoint-xxs-max {
  @media (max-width: $breakpoint-xxs-max) {
    @content;
  }
}
@mixin breakpoint-xs-max {
  @media (max-width: $breakpoint-xs-max) {
    @content;
  }
}
@mixin breakpoint-s-max {
  @media (max-width: $breakpoint-s-max) {
    @content;
  }
}
@mixin breakpoint-im-max {
  @media (max-width: $breakpoint-im-max) {
    @content;
  }
}
@mixin breakpoint-m-max {
  @media (max-width: $breakpoint-m-max) {
    @content;
  }
}
@mixin breakpoint-l-max {
  @media (max-width: $breakpoint-l-max) {
    @content;
  }
}
@mixin breakpoint-xl-max {
  @media (max-width: $breakpoint-xl-max) {
    @content;
  }
}
@mixin breakpoint-xxl-max {
  @media (max-width: $breakpoint-xxl-max) {
    @content;
  }
}

@mixin input-placeholder {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }
}

// Tables
@mixin default-table {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    @include breakpoint-l-max {
      display: block;
      padding: $spacing-m 0;
      border-bottom: 1px solid $border-gray;
    }
  }

  th {
    text-align: left;
    padding: $spacing-l $spacing-s $spacing-m 0;

    @include breakpoint-l-max {
      display: none;
    }
  }

  th,
  td {
    @include breakpoint-l {
      border-bottom: 1px solid $border-gray;
    }

    &:last-child {
      text-align: right;
      padding-right: 0;
    }

    &:empty {
      @include breakpoint-l-max {
        display: none;
      }
    }
  }

  td {
    padding: $spacing-l $spacing-s $spacing-l 0;

    @include breakpoint-l-max {
      display: flex;
      justify-content: space-between;
      padding: $spacing-s 0;
    }
  }
}

@mixin simple-chrome-scrollbar {
  //scrollbar to override chrome's default mobile scroll
  @include breakpoint-s-max {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #e4e0e099;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 20px;
      border: 3px solid transparent;
    }
  }
}

@mixin default-content-width {
  position: relative;
  width: 90%;
  max-width: $breakpoint-xl;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint-s {
    width: 85%;
  }

  @include breakpoint-m {
    width: 92%;
  }
}

@mixin  full-size {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin image-credit-text {
  margin-top: $spacing-s;
  text-align: right;
  color: $medium-gray;
  @include responsive-font-size(14px, false, 16px);
}
