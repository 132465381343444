.link-list {
  width: 100%;
  border-left: 1px solid $border-gray;
  padding-left: $spacing-l;

  &:not(:first-child) {
    margin-top: $spacing-xl;
  }

  h2 {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: $spacing-l 0 0;
  }

  li {
    &:not(:last-child) {
      margin-bottom: $spacing-s;
    }
  }
}

.link-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
}

.link-list-icon {
  margin-left: auto;
}
