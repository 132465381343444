.store-meta {
  $margin: 1rem;

  background-color: $light-cyan;

  p {
    margin: 0;
  }

  &__heading {
    margin: 0;
    font-weight: bold;
  }

  &__content {
    padding: 1rem 1rem 0 1rem;

    @include breakpoint-m {
      display: flex;
    }
  }

  &__container {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $border-gray;

    @include breakpoint-m {
      flex: 0 0 calc(100% / 3 - #{$margin});
      margin: 0 0 0 $margin;
      padding: 0 0 0 1rem;
      border-top: 0;
      border-left: 1px solid $border-gray;

      &:first-child {
        margin-left: 0;
        margin-top: 0;
        padding-left: 0;
        border: 0;
      }
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }

  &__link {
    display: block;
  }

  &__address {
    font-style: inherit;
  }

  &__actions {
    padding: 1rem 1rem 1rem 1rem;
  }
}
