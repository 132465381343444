.find-store-block {
  position: relative;
  z-index: 2;
  padding: $spacing-xl $spacing-l;
  background-color: $dark-cyan;
  background-image: url(../../assets/images/map-background.jpg);
  background-size: cover;
  color: white;

  h2 {
    margin-top: 0;
    text-align: center;
  }
}

.find-store-block-content {
  position: relative;
  max-width: 450px;
  margin: 0 auto;

  form {
    position: relative;
    z-index: 2;
  }
}

.find-store-block-icon {
  display: block;
  margin: 0 auto;
  height: 30px;
  width: auto;
  margin-bottom: $spacing-m;
}

.find-store-block-search-icon {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100%;
  width: 80px;
  padding-right: $spacing-l;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: linear-gradient(
    90deg,
    rgba(darken($dark-cyan, 5), 0.01),
    rgba(darken($dark-cyan, 5), 0.99) 40%
  );
}

.find-store-block-results {
  list-style: none;
  position: absolute;
  left: 0;
  transform: translateY(-2px);
  width: 100%;
  margin: 0;
  padding: $spacing-m $spacing-l $spacing-l;
  background-color: white;
  box-shadow: 6px 8px 40px rgba(black, 0.1);
}
