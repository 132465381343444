.products-header {
  $self: &;
  background: white;

  &__expandable-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    will-change: max-height;
  }

  &__content {
    @include breakpoint-l {
      display: flex;
      align-items: center;
      margin-left: -$spacing-m;
    }
  }

  &__toggle {
    border: none;
    font-family: inherit;
    padding:30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-align: left;
    background-color: white;
    @include responsive-font-size(16px, false, 18px);
    line-height: 1.1;
    font-weight: bold;

    @include breakpoint-l {
      line-height: 0.9;
    }

    &:focus-visible,
    &:hover {
      box-shadow: $box-shadow--card;
    }

    #{$self}--expanded & {
      &:focus-visible,
      &:hover {
        box-shadow: none;
      }
    }
  }

  &__icon {
    display: block;
    transition: transform 0.7s ease;
    margin-left: 20px;

    #{$self}--expanded & {
      transform: rotate(180deg);
    }
  }

  &__image {
    margin-top: $spacing-m;

    .image {
      width: 100%;
    }

    @include breakpoint-l {
      flex: 0 0 calc(33.333% - #{$spacing-m});
      width: 33.333% - #{$spacing-m};
      margin-left: $spacing-m;
      margin-top: 0;

      .image {
        width: auto;
      }
    }
  }

  &__text {
    padding: 10px 30px 10px;
    @include breakpoint-l {
      padding: 0;
      flex: 1 0 calc(66.666% - #{$spacing-m});
      width: 66.666% - #{$spacing-m};
      margin-left: $spacing-m;
      align-self: flex-start;
    }
  }

  &__title {
    margin: 0 0 $spacing-m 0;
    text-transform: uppercase;
  }

  &__lead {
    margin: 0;
    margin-left: 0;
    max-width: 100% !important;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include breakpoint-l {
      text-align: left;
      margin: 0;
      padding: 0 20px 30px 30px;
      align-items: flex-start;
    }
  }
}