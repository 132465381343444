.checkout-login {
  @include breakpoint-l {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.checkout-login-login,
.checkout-login-register {
  @include breakpoint-l {
    width: 45%;
  }
}

.checkout-login-register {
  margin-top: $spacing-xl;

  @include breakpoint-l {
    margin-top: 0;
  }
}

.checkout-login-footer {
  width: 100%;
  margin-top: $spacing-xl;

  &.is-right-aligned {
    display: flex;
    justify-content: flex-end;
  }
}
