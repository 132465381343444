.message {
  position: fixed;
  z-index: 100;
  top: $spacing-l;
  left: 0;
  width: 100%;

  &.before-enter,
  &.leaving {
    opacity: 0;
    transform: translateY(-100%);
  }

  &.entering {
    opacity: 1;
    transform: none;
    transition: transform 0.5s $ease-out, opacity 0.3s;
  }

  &.before-leave {
    transition: transform 0.4s $ease-in, opacity 0.2s 0.2s;
  }
}

.message-inner {
  display: flex;
  justify-content: space-between;
  background-color: $dark-cyan;
  color: white;
  padding: $spacing-xl + $spacing-m;

  .message.is-error & {
    background-color: $orange;
  }

  p {
    margin: 0;
  }
}

.message-close {
  color: white !important;
}
