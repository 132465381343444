.mobile-sticky-header {
  @include breakpoint-l-max {
    position: fixed;
    top: $mobile-top-bar-height;
    left: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 100;

    &--hidden {
      transform: translateY(-110%);
    }

    &__spacer {
      height: 116px; //mobile header height
    }
  }
}