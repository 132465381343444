.product-card {
  $self: &;
  position: relative;
  padding: 30px;
  background-color: white;
  box-shadow: $box-shadow;
  width: 100%; // Needed for rendering ProductCard as a flex-child

  @include breakpoint-m {
    padding-top: 60px;
  }

  &.moodboard {
    @include full-size;
    z-index: 4;
    overflow: hidden;

    @include breakpoint-m {
      padding-top: 30px;
    }

    #{$self}-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    #{$self}-details{
      ul{
        margin: 0;
      }
    }
    #{$self}-image{    
      height: 170px;
      max-height: 100%;
      padding-top: 0;
      align-self: center;

      *{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .product-card-image-wrapper{
      overflow: hidden;
      margin-top: auto;
      margin-bottom: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }
}

.product-card-content {
  .product-card.theme-jumbo & {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

a.product-card {
  display: block;
  text-decoration: none;
  color: inherit;
  transition: $transition--default;

  &:hover {
    box-shadow: $box-shadow--card;
  }
}

.product-card-image {
  position: relative;
  margin-bottom: $spacing-m;
  width: 100%;
  padding-top: 50%;

  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
  }

  .product-card.theme-jumbo & {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.product-card-tag {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 30px;
  margin: 0;
}

.product-card-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $spacing-s;
  color: $medium-gray;

  >ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-weight: 300;
    line-height: 1;

    li {
      display: flex;
      align-items: center;
      margin-right: $spacing-s;
      margin-top: $spacing-s;

      &:not(:last-child):after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 10px;
        margin-left: $spacing-s;
        background-color: currentColor;
      }

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }
    }
  }
}

.product-card-text {
  @include cf;
  display: flex;
  align-items: flex-start;
  margin-top: $spacing-m;
}

.product-card-text-inner {
  flex-grow: 1;
}

.product-card-brand {
  margin: 0;
  padding-right: $spacing-l;
}

.product-card-name {
  margin: $spacing-s 0 0;
  color: $medium-gray;
}

.product-card-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $spacing-s;
}

.product-card-colors-tag {
  color: $medium-gray;
  margin-left: $spacing-s;
}

.product-card-color {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  margin-right: $spacing-s;
  background-size: cover;
  background-position: center center;
}