.my-consents {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-top: $spacing-m;

    &:first-child {
      margin-top: 0;
    }

    svg {
      margin-right: 15px;
      color: $medium-gray;
    }
  }
}
