.accordion {
  &--narrow {
    @include breakpoint-l {
      width: 65%; // to accomodate for varying widths of the article page (with and without sidebar)
      margin: 0 auto;
    }
  }

  button {
    appearance: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: left;
    margin-bottom: $spacing-s;

    &.is-active .accordion-icon {
      transform: rotate(-180deg);
    }

    &:hover {
      .accordion-header {
        background-color: darken($dark-cyan, 5);
        color: white;
        box-shadow: 0 10px 10px rgba(4, 23, 23, 0.25),
          0 3px 6px rgba(0, 0, 0, 0.3);
      }

      .accordion-header-text {
        border-bottom-color: white;
      }
    }
  }
}

.accordion-content {
  padding: $spacing-l $spacing-xl;
  background-color: $light-cyan;
  border-bottom: $spacing-s solid white;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-m;
  padding-left: $spacing-l;
  background-color: $light-cyan;
  transition: $transition--default;
}

.accordion-header-text {
  margin: 0;
  padding-bottom: 3px;
  font-size: 1rem;
  border-bottom: 3px solid transparent;

  &--active {
    border-bottom-color: black;
  }
}

.accordion-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  transition: transform 0.3s $ease-out;
}
