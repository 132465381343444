.appointment-booking-service-list {
  margin-top: 80px;

  @include simple-chrome-scrollbar;
  overflow-y: auto;
  max-height: 50vh;
  margin-bottom: 80px;

  @include breakpoint-im {
    margin-top: 70px;
    margin-bottom: 0;
  }
}
