@mixin narrow-item-mobil-breakpoint {
  @media all and (min-width: $breakpoint-xxs) and (max-width: $breakpoint-l-max){
    @content;
  }
}
.submenu {
  display: none;

  &--active {
    display: block;
  }
}

.submenu-content {
  padding-top: $spacing-l;

  @include breakpoint-l {
    display: flex;
    justify-content: space-between;
    padding: $spacing-xl 0 $spacing-xxl;
  }

  @include breakpoint-l-max {
    width: 100% !important;
    padding-bottom: $spacing-xl;
  }

  .submenu-title {
    margin: 0;
    margin-bottom: $spacing-l;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 15px;
  }
}

.submenu-links-container {
  overflow: hidden;
  @include narrow-item-mobil-breakpoint {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  @include breakpoint-l {
    display: flex;
    width: 100%;
    padding-right: $spacing-xl;
  }

  @include breakpoint-xxl {
    padding-right: 0;
  }
}

.submenu-link-list {
  margin-top: $spacing-xl;

  &:first-child {
    margin-top: 0;
  }

  @include narrow-item-mobil-breakpoint {
    width: 100%;
  }

  @include breakpoint-l {
    width: 25%;
    margin-top: 0;
  }

  ul {
    @include breakpoint-l {
      padding-right: $spacing-l;
    }
    margin-bottom: 40px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &.is-two-columns {
    @include breakpoint-l {
      width: 50%;
    }
    ul {
      padding-right: 0;
      column-count: 2;
    }
  }

  &.is-narrow{
    width: auto;
      @include narrow-item-mobil-breakpoint {
        width: 50%;
        & + .submenu-link-list{
          width: 50%;
          margin-top: 0;
      }
    }

    @include breakpoint-l {
      max-width: 25%;
      padding-right: 20px;
    }
    @include breakpoint-xl {
      width: 25%;
      padding-right: 0;
    }
  }
}

.submenu-link-lists {
  @include breakpoint-l {
    display: flex;
  }
}

.submenu-link {
  display: block;
  position: relative;
  color: $medium-dark-gray;
  line-height: 1.2;

  &:hover {
    color: $dark-gray;
  }

  svg {
    color: $orange;
    height: 10px;
    margin-top: 3px;
  }
}

.submenu-category-link,
.submenu-shape-link {
  margin-bottom: 0 !important;
}

.submenu-link-icon {
  position: absolute;
  top: 2px;
}

.submenu-close {
  position: absolute;
  right: $spacing-xl;
  top: $spacing-xl;
  display: none !important;

  @include breakpoint-l {
    display: flex !important;
  }
}
