.appointment-booking-shop-item {
  padding: 9px 11px 10px;
  border: 1px solid #dbe7e7;
  border-radius: 2px;
  margin: 0 0 10px;
  cursor: pointer;
  transition: all .25s ease;

  &:hover {
    border-color: #007a7d;
  }

  &__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000;

    @include breakpoint-im {
      font-size: 21px;
      line-height: 30px;
    }
  }

  &__address {
    display: none;
    font-size: 16px;
    line-height: 19px;
    color: #7b7b7b;

    @include breakpoint-im {
      display: block;
    }
  }
}
