.appointments {
  dt {
    &:not(:first-child) {
      margin-top: $spacing-l;
    }
  }

  dd {
    margin-left: 0;
  }

  // Specificity hack
  :root & p {
    margin: $spacing-l 0 0;
  }
}
