.filter-group {
  margin-bottom: $spacing-xl + $spacing-l;

  @include breakpoint-m {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    line-height: 1;
    margin: 0;
  }

  > a {
    display: block;
    margin-top: $spacing-l;
    max-width: 150px;
  }
}

.filter-group-content {
  margin-top: 15px;
}
