.appointment-booking-hint {
  text-align: center;
  margin: 0 auto;

  span {
    display: inline-block;
    vertical-align: top;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #007a7d;
    padding: 5px;
    border-top: 1px solid #bfdfe0;

    @include breakpoint-xs {
      font-size: 16px;
      white-space: pre;
    }
  }
}
