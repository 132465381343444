.appointment-booking-modal {

  @include breakpoint-l {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: #f1f9f9;
    }
  }

  .modal-background {
    display: none;
  }

  &__inner {
    width: 100% !important;
    height: 100%;

    @include breakpoint-l {
      max-width: 886px !important;
    }
  }

  &__content {
    width: 100%;
    background-color: #f1f9f9;

    .content-container {
      width: 100%;
      @include breakpoint-l-max {
        position: absolute;
        height: 100%;
      }
    }
    @include breakpoint-l{
      top: 50%;
      transform: translateY(-50%) !important;
    }

    @include breakpoint-l-max {
      height: 100%;
      top: 0;
    }
  }

  .modal-label {
    top: 61px;
    left: 15px;
    color: #707070;
    text-transform: uppercase;

    @include breakpoint-l {
      top: 50px;
      font-size: 21px;
      line-height: 21px;
    }
  }

  .modal-close-text {
    top: 61px;
    color: #707070;
    padding-right: 3px;

    @include breakpoint-l {
      top: 50px;
      font-size: 21px;
      line-height: 21px;
      padding-right: 5px;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 100%;
      width: 16px;
      height: 1px;
      background: #707070;

      @include breakpoint-l {
        top: 10px;
        width: 25px;
      }
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .appointment-booking-modal__inner:focus,
  .appointment-booking-modal__content:focus {
    outline: none;
  }
}
