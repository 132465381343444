.appointment-booking-title {
  display: block;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #2c2c2c;
  white-space: pre;
  margin: 0 auto 20px;
}
