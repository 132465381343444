.appointment-booking-date-item {
  position: relative;
  // width: 54px;
  width: 100%;
  height: 65px;
  text-align: center;
  padding: 10px 0;
  border: solid 1px #78b9bb;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    border-color: #007a7d;
  }

  &.first-day,
  .is-current & {
    overflow: visible;
  }

  &.is-active {
    background-color: #007a7d;
    border-color: #007a7d;

    .appointment-booking-date-item__text {
      color: #f1f9f9;
    }

    .appointment-booking-date-item__date {
      color: #f1f9f9;
    }
  }

  &.is-disabled {
    background-color: transparent;
    border-color: #e1e8e8;
    pointer-events: none;

    .appointment-booking-date-item__text {
      text-decoration: line-through;
      text-decoration-color: $orange;
    }

    .appointment-booking-date-item__date {
      color: #707070;
    }
  }

  &__text {
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #707070;
    padding-bottom: 7px;
  }

  &__date {
    display: block;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    color: #000;
  }

  &__month {
    position: absolute;
    top: 100%;
    left: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #666;
    padding-top: 10px;
    pointer-events: none;
  }
}
