.link-to-main-site {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: black;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  &--in-header {
    @include breakpoint-m-max {
      color: white;
    }
  }

  * {
    align-self: center;
  }

  &__icon-before {
    color: $orange;
    width: 22px;
    margin-right: 8px;
  }

  &__text {
    white-space: nowrap;
    display: block;
    line-height: 1;
    font-size: 14px;

    @include breakpoint-m {
      font-size: 18px;
    }
  }

  &__icon-after {
    color: $orange;
    display: block;
    width: 71px;
    margin-left: 3px;
    margin-bottom: -2px;

    @include breakpoint-m {
      width: 100px;
      margin-left: 8px;
      margin-bottom: -3px;
    }
  }
}