.appointment-booking-region-item {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  padding: 8px 11px 9px;
  border: 1px solid #dbe7e7;
  border-radius: 2px;
  margin: 0 0 10px;
  cursor: pointer;
  transition: all .25s ease;

  &:hover {
    border-color: #007a7d;
  }
}
