.inspirational-items {
  $self: &;

  &--single {
    #{$self}__item {
      flex: 1 1 auto;
    }
  }

  &__header {
    text-align: center;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    @include breakpoint-s {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$spacing-m;
      margin-top: -$spacing-l;
    }
  }

  &__item {
    margin-bottom: $spacing-l;

    @include breakpoint-s {
      flex: 0 0 calc(50% - #{$spacing-m});
      margin-left: $spacing-m;
      margin-top: $spacing-l;
      margin-bottom: 0;
    }
  }
}
