.header {
  position: relative;
  z-index: 10;
  background-color: white;
}

.header-mobile-top-bar {
  display: flex;
  align-items: center;
  background-color: black;
  height: $mobile-top-bar-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;

  @include breakpoint-l {
    display: none;
  }
}

.header-mobile-top-bar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.header-mobile-top-bar-links {
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 20px);
  white-space: nowrap;
  flex-wrap: nowrap;

  & > *{
    margin-right: $spacing-l;
    line-height: 1.2;
  }
}

.header-mobile-top-bar .header-mobile-top-bar__book-appointment{
  height: 30px;
  padding: 8px 13px 0;

  margin-right: 16px;
  white-space: nowrap;
}


.header-bar {
  position: relative;
  z-index: 3;
  background-color: white;

  @include breakpoint-l-max {
    box-shadow: $box-shadow;
  }
}

.header-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $header-bar-padding-s 0;
  color: $medium-gray;

  @include breakpoint-m {
    padding: $spacing-l 0;
  }
}

.header-logo {
  flex-shrink: 0;

  img {
    display: block;
    width: 153px;
    height: auto;

    @include breakpoint-m {
      width: 270px;
    }
  }
}

.header-mobile-menu-toggle {
  @include remove-button-styles;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing-l;
  height: 46px;
  width: 46px;
  border-radius: 100%;
  background-color: $light-gray;
  color: black;

  &:hover {
    background-color: $border-gray;
  }

  @include breakpoint-l {
    display: none;
  }

  &.is-active {
    background-color: black;
    color: white;
  }
}

.header-hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 14px;
  width: 18px;

  @include breakpoint-m {
    height: 19px;
    width: 25px;
  }

  .header-mobile-menu-toggle.is-active & {
    .top {
      transition-delay: 0s;
      transform: translateY(6px);

      @include breakpoint-m {
        transform: translateY(8px);
      }
    }
    .bot {
      width: 100%;
      transition-delay: 0s;
      transform: translateY(-6px);

      @include breakpoint-m {
        transform: translateY(-8px);
      }
    }

    .top:before {
      transition-delay: 0.15s;
      transform: rotate(45deg);
    }
    .bot:before {
      transition-delay: 0.15s;
      transform: rotate(-45deg);
    }

    .mid {
      transform: scaleX(0);
      opacity: 0;
    }
  }
}

.header-hamburger-line {
  position: relative;
  display: block;
  height: 2px;
  width: 100%;
  transition: transform 0.4s 0.1s $ease-in-out, opacity 0.4s $ease-in-out;

  @include breakpoint-m {
    height: 3px;
  }

  &.bot {
    width: 50%;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: currentColor;
    border-radius: 20%;
    transition: transform 0.4s $ease-out, background-color 0.3s 0.6s;
  }
}

.header-nav-left,
.header-nav-right {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-nav-left {
  @include breakpoint-l-max {
    display: none;
  }

  > li {
    margin-right: $spacing-l;
  }
}

.header-nav-right {
  > li {
    margin-left: $spacing-l;
  }

  > li.small-margin {
    margin-left: $spacing-m;
  }
}

.header-nav-search-button {
  @include breakpoint-l-max {
    display: none;
  }
}

.header-support-menu-link {
  @include breakpoint-l-max {
    display: none;
  }
}

.header-book-appointment-link {
  color: white !important;
  display: block;

  .header-bar & {
    @include breakpoint-l-max {
      display: none;
    }
  }
}

.header-phone-icon {
  position: relative;
  margin-right: $spacing-m;
  top: 0.2em;
}

.header-cart-button {
  position: relative;

  svg {
    margin-top: -2px;
  }
}

.header-cart-count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 20px;
  width: 20px;
  right: -5px;
  top: -5px;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  background-color: white;
  border-radius: 100%;
  color: black;
  box-shadow: $box-shadow, $box-shadow;
}

.header-login,
.header-cart,
.header-search {
  top: $header-bar-height-s !important;
  height: auto !important;
  bottom: 0;

  @include breakpoint-m {
    top: $header-bar-height + $header-mobile-black-bar-height !important;
  }

  @include breakpoint-l {
    top: $header-bar-height + 1px !important;
  }
}

.header-login-inner,
.header-cart-inner,
.header-search-inner {
  display: flex;
  justify-content: flex-end;

  @include breakpoint-m-max {
    width: 100%;
  }
}

.header-search-inner {
  width: 100%;
  max-width: none;
}

.header-login-content,
.header-cart-content,
.header-search-content {
  width: 100%;
  background-color: white;
  border-top: 1px solid $border-gray;
}

.header-cart-content {
  @include breakpoint-m {
    max-width: 700px;
  }

  @include breakpoint-xl {
    right: -$spacing-xl - $spacing-l;
  }
}

.header-login-content {
  @include breakpoint-m {
    max-width: 550px;
  }

  @include breakpoint-xl {
    right: -$spacing-xl - $spacing-l;
  }
}

.header-login-link {
  @include breakpoint-l-max {
    display: none;
  }
}
