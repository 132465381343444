.login-form {
  position: relative;

  &.theme-mini {
    padding: $spacing-xl 0;

    @include breakpoint-m {
      padding: $spacing-xl + $spacing-l;
      padding-top: $spacing-xl;
    }
  }

  &.theme-maxi {
    max-width: 500px;
    margin: 0 auto;
    margin-top: $spacing-xxl;
  }

  h1,
  h2 {
    margin: 0;
  }
}

.login-form-form {
  margin-top: $spacing-xl;
}

.login-form-submit {
  margin-top: $spacing-l;
}

.login-form-register {
  margin-top: $spacing-xl;

  > p {
    margin: 0;
    margin-right: $spacing-l;
  }
}

.login-form-register-action {
  margin-top: 0.5rem;
}

.login-form-forgot-password {
  display: inline-block;
  margin: $spacing-m 0;
}
