.link-row {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include breakpoint-m {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: -$spacing-m;
    }
  }

  &__item {
    margin-bottom: 1rem;

    @include breakpoint-m {
      flex: 0 0 calc(100% / 3 - #{$spacing-m});
      width: calc(100% / 3 - #{$spacing-m});
      margin-left: $spacing-m;
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0.25rem;
  }

  &__link {
    font-size: 16px;
    cursor: pointer;
  }
}
