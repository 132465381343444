.booking-page {
  max-width: 800px;
  margin: 0 auto;

  iframe {
    width: 100%;
    border: none;
    margin: $spacing-xl 0;
  }
}
