.appointment-booking-footer {
  text-align: center;
  padding: 75px 0 30px;

  @include breakpoint-im {
    padding-top: 50px;
  }

  &__text {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #2c2c2c;
  }

  &__link {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 23px;
  }
}
