.image-link-list {
  display: block;

  h2 {
    text-align: center;
    margin: 0 auto $spacing-m;

    @include breakpoint-m {
      margin: 0 auto $spacing-l;
    }
  }

  .image-link-list-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: -$spacing-m;

    @include breakpoint-m {
      margin-left: -$spacing-m;
    }

    &--grow-items {
      .image-link-list-item {
        flex-grow: 1;
      }
    }

    &--single-column {
      .image-link-list-item {
        @include breakpoint-m {
          flex: 0 1 calc(100% - #{$spacing-s * 2});
        }

        @include breakpoint-l {
          flex: 0 1 calc(100% - #{$spacing-s * 2});
        }
      }
    }
  }

  .image-link-list-item {
    margin-top: $spacing-m;
    flex: 0 0 100%;

    @include breakpoint-m {
      flex: 0 1 calc(50% - #{$spacing-s * 2});
      margin-left: $spacing-m;
    }

    @include breakpoint-l {
      flex: 0 1 calc(33.3% - #{$spacing-s * 2});
    }
  }
}
