.tag-item {
    &__label {
        cursor: pointer;
        background: white;
        display: block;
        padding: 7px 10px;
        @include responsive-font-size(14px, false, 16px);
        text-transform: uppercase;
        color: black;
        border: 1px solid transparent;
        outline: none;

        @include breakpoint-s {
            padding: 11px 20px;
        }

        &:focus-visible,
        &:hover {
            color: $dark-orange;
            text-decoration: underline;
        }

        &--checked {
            border: 1px solid $orange;
            cursor: default;

            &:focus-visible,
            &:hover {
                color: black;
                text-decoration: none;
            }
        }
    }
}