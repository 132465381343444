.tag-filter {

    //margin: 0 0 $spacing-xlarge;
    padding: 20px 0 0;

    @include breakpoint-s{
        padding: 35px 0 15px;
    }

    &__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        &-item{
            margin: 0 15px 20px 0;

            @include breakpoint-s{
                margin: 0 10px 20px 0;
            }
        }
    }
}
