.products-footer {
  $self: &;
  text-align: center;
  margin: 2rem auto 0 auto;

  &__title {
    margin: 0 0 $spacing-m 0;
    text-transform: uppercase;
  }

  &__lead {
    margin: 0;
  }
}
