.replacement-product-banner {
  display: flex;
  flex-direction: column;
  padding: $spacing-l $spacing-l $spacing-m;
  background-color: $light-gray;
  height: 100%;

  @include breakpoint-l {
    padding: $spacing-xl $spacing-l $spacing-m;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
    line-height: 1.2;
  }

  p {
    margin: $spacing-s 0 0;
  }
}

.replacement-product-banner-image {
  margin-top: $spacing-l;
  margin-left: -$spacing-m;
  margin-right: -$spacing-m;
  background-color: white;

  @include breakpoint-l {
    margin-top: auto;
    min-height: 200px;
  }

  img {
    margin: 0 auto;
  }
}
