.appointment-booking {
  height: 100%;
  padding: 104px 15px 0;
  background-color: #f1f9f9;
  margin: 0 auto;
  overflow-y: auto;
  -ms-overflow-style: none;

  @include breakpoint-im {
    max-width: 530px;
    max-height: 1024px;
  }

  &--bottom-padding {
    padding-bottom: 92px;
  }

  &.step-5 {
    @include breakpoint-im {
      padding-right: 73px;
      padding-left: 73px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__top-decoration {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100px;
    background: -moz-linear-gradient(top,  rgba(241,249,249,1) 0%, rgba(241,249,249,1) 85%, rgba(241,249,249,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(241,249,249,1) 0%,rgba(241,249,249,1) 85%,rgba(241,249,249,0) 100%);
    background: linear-gradient(to bottom,  rgba(241,249,249,1) 0%,rgba(241,249,249,1) 85%,rgba(241,249,249,0) 100%);
  }

  &__logo {
    position: absolute;
    top: 43px;
    left: 50%;
    z-index: 1;
    width: 90px;
    height: 18px;
    transform: translateX(-50%);

    @include breakpoint-im {
      top: 28px;
      width: 100px;
      height: 20px;
    }
  }
}
