.checkout-register-form-footer {
  display: flex;
  align-items: center;
  margin-top: $spacing-xl;
}

.checkout-register-form-submit {
  margin-right: $spacing-l;
}

.checkout-register-form-bullets {
  @include remove-list-styles();
  background-color: $light-cyan;
  padding: $spacing-l;
  margin-top: $spacing-l;

  li {
    position: relative;
    padding-left: $spacing-l;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 11px;
      width: 12px;
      top: 4px;
      background-color: transparent;
      background-image: url(../../assets/images/checkmark.svg);
    }
  }
}
