$magazine-header-height: 174px;
$magazine-header-height-mobile: 120px;

.magazine-header {
    height: $magazine-header-height-mobile;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint-m-max {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.104759);
    }

    @include breakpoint-m {
        height: $magazine-header-height;
    }

    &__top-content {
        width: 100%;
        background: black;
        padding-top: 4px;

        @include breakpoint-m {
            background: none;
            padding-top: 0;
        }

        &-inner {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-self: flex-start;
        }
    }

    &__bottom-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint-xl {
            justify-content: flex-start;
        }
    }

    &__back-link {
        align-self: center;

        @include breakpoint-m {
            align-self: flex-start;
            margin-top: 37px;
        }
    }

    &__search {
        &-wrapper {
            @include breakpoint-m {
                margin-top: 24px;
            }
        }

        &-link {
            @include breakpoint-m-max {
                margin-right: -14px;
                color: white !important;
                background-color: transparent !important;
            }
        }

        &-modal {
            top: $magazine-header-height-mobile !important;
            height: auto !important;
            bottom: 0;

            @include breakpoint-m {
                top: $magazine-header-height !important;
            }

            &-inner {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                max-width: none;
            }

            &-content {
                width: 100%;
                background-color: white;
                border-top: 1px solid $border-gray;
            }
        }
    }

    &__cta-links {
        margin: 13px 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        list-style: none;
        align-self: flex-start;
        padding: 0;

        @include breakpoint-m {
            margin: 27px 12px 0 auto;
        }

        &-item {
            margin-right: 13px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__cta-link {
        display: block;
        color: white;
        white-space: nowrap;
        text-decoration: underline;
        font-size: 14px;
        line-height: 1.42;
        letter-spacing: 0.1px;

        &:first-letter {
            text-transform: capitalize;
        }

        &:hover,
        &:focus-visible {
            cursor: pointer;
            box-shadow: $box-shadow--button;
            text-decoration: underline;
            background-color: darken($dark-cyan, 5);
        }

        @include breakpoint-m {
            padding: 9px 15px;
            background: $clinical;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 16px;
            line-height: 1.437;
            letter-spacing: 0.5px;
        }
    }

    &__logo {
        display: inline-flex;
        margin: 0 auto;
        max-width: 317px;
        width: auto;

        @include breakpoint-m {
            max-width: 572px;
        }

        &-image {
            max-height: 60px;

            @include breakpoint-m {
                max-height: 80px;
            }
        }
    }

}