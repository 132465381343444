.add-to-cart-confirmation {
  position: static !important;
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.add-to-cart-confirmation-content {
  position: relative;
  max-width: 700px;
  background-color: white;
  padding: $spacing-l;

  @include breakpoint-m {
    padding: $spacing-xl;
    padding-top: $spacing-xl;
  }

  h2 {
    margin: 0;
    padding-bottom: $spacing-l;
    border-bottom: 1px solid $border-gray;
  }
}

.add-to-cart-confirmation-icon {
  height: $spacing-l;
  width: $spacing-l;
  color: $orange;
  margin-right: $spacing-l;
}

.add-to-cart-confirmation-buttons {
  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-l;
  }
}

.add-to-cart-confirmation-button {
  margin-top: $spacing-l;

  @include breakpoint-m {
    width: calc(50% - #{$spacing-m});
    margin-top: 0;
  }
}

.add-to-cart-confirmation-close {
  width: 100%;
  text-align: left !important;
}
