.magazine-landing-page {
  &__hero {
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 433px;
    position: relative;
    overflow: hidden;

    @include breakpoint-m {
      height: auto;
      min-height: 200px;
      max-height: calc(100vh - 270px);

      &:after {
        content: "";
        display: block;
        width: 100%;
        padding: 0 0 45%;
      }
    }
  }

  &__filter {
    background: $light-orange;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 40px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    &:after {
      content: '';
      flex: calc((100% - 20px)/3);
      max-width: calc((100% - 20px)/3);
    }

    &-item {
      width: 100%;
      margin-bottom: 40px;

      @include breakpoint-m {
        &:nth-child(6n + 4),
        &:nth-child(6n + 5) {
          flex: calc((100% - 10px)/2);
          max-width: calc((100% - 10px)/2);
        }

        &:nth-child(6n + 1),
        &:nth-child(6n + 2),
        &:nth-child(6n + 3) {
          flex: calc((100% - 20px)/3);
          max-width: calc((100% - 20px)/3);
        }

        &:nth-child(6n) {
          width: 100%;
        }
      }
    }
  }
}