.customer-data-import-form {
  @include breakpoint-l-max {
    margin-top: $spacing-l;
  }
}

.customer-data-import-form-input {
  max-width: 300px;
}

.customer-data-import-form-actions {
  margin-top: $spacing-l;
  display: inline-flex;
  align-items: center;

  > * {
    margin-right: $spacing-l;
  }
}
