.page-spinner {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(white, 0.6);

  &.before-enter {
    opacity: 0;

    .page-spinner-wrapper {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  &.entering,
  &.before-leave {
    opacity: 1;
    transition: opacity 0.5s;

    .page-spinner-wrapper {
      opacity: 1;
      transform: none;
      transition: transform 0.5s $ease-out, opacity 0.5s;
    }
  }

  &.leaving {
    opacity: 0;

    .page-spinner-wrapper {
      opacity: 0;
      transform: scale(0.5);
    }
  }
}

.page-spinner-wrapper {
  position: fixed;
  left: 50%;
  top: 50vh;
  padding: $spacing-xl;
  border-radius: 100%;
  margin-top: -25px - $spacing-xl;
  margin-left: -25px - $spacing-xl;

  @include breakpoint-m {
    margin-top: -50px - $spacing-xl;
    margin-left: -50px - $spacing-xl;
  }

  .is-contained & {
    position: absolute;
    top: 50%;
  }
}
