.find-store-page {
  margin-bottom: $spacing-xxl;

  h1 {
    margin: $spacing-xxl 0 0;
  }
}

.find-store-page-ingress {
  font-weight: 300;
  margin: $spacing-m 0 0;
}

.find-store-page-search {
  margin-top: $spacing-xl;

  @include breakpoint-m {
    max-width: 500px;
  }
}

.find-store-page-stores {
  margin-top: $spacing-xl;
}

.find-store-page-map {
  margin-top: $spacing-xl;
}

.find-store-page-no-results {
  margin-top: $spacing-xl;
}
