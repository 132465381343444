$square-proportion: 0 0 99.4%;

.moodboard-block {
  $self: &;
  background-color: $light-cyan;
  padding: $spacing-l 0;
  box-shadow: -$spacing-xxl 0 0 0 $light-cyan, $spacing-xxl 0 0 0 $light-cyan;

  @include breakpoint-l {
    padding: $spacing-xl 0;
  }

  &__title{
    text-align: center;
    margin: 0 0 $spacing-xl;
  }

  &__items {
    width: calc(100% + 10px);
    margin-right: -10px;
  }

  &__sizer {
    max-width: calc(25% - 10px);

    @include breakpoint-l-max {
      max-width: calc(50% - 10px);
    }
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    max-width: calc(25% - 10px);
    position: relative;
    margin-bottom: 10px;

    &-spacer {
      width: 100%;
      position: relative;
      z-index: 1;

      #{$self}__item--format-tall &,
      #{$self}__item--format-square-big & {
        &:before {
          width: 100%;
          content: "";
          display: block;
          position: relative;
          z-index: 1;
          padding: $square-proportion;
          margin-bottom: 10px;
        }
      }

      #{$self}__item--format-square-big &,
      #{$self}__item--format-rectangle & {
        width: calc(50% - 5px);
      }

      &:after {
        width: 100%;
        content: "";
        display: block;
        position: relative;
        z-index: 1;
        padding: $square-proportion;
      }
    }

    &--format-square-big {
      max-width: calc(50% - 10px);

      @include breakpoint-l-max {
        max-width: calc(100% - 10px);
      }
    }

    &--format-square-small {
      @include breakpoint-l-max {
        max-width: calc(50% - 10px);
      }

      &#{$self}__item--product {
        @include breakpoint-s-max {
          max-width: calc(100% - 10px) !important;
        }
      }
    }

    &--format-tall {
      @include breakpoint-l-max {
        max-width: calc(50% - 10px);
      }
    }

    &--format-rectangle {
      max-width: calc(50% - 10px);

      &:after {
        width: calc(50% - 5px);
        float: left;
      }

      @include breakpoint-l-max {
        max-width: calc(100% - 10px);
      }

      &#{$self}__item--product {
        @include breakpoint-s-max {
          #{$self}__item-spacer {
            width: 100%;
          }
        }
      }
    }

    &-image {
      z-index: 2;
      @include full-size;
    }

    &-text {
      position: absolute;
      bottom: 8px;
      right: 8px;
      z-index: 3;
      color: $medium-dark-gray;
      font-size: 16px;
      line-height: 1;

      @include breakpoint-s{
        bottom: 15px;
        right: 15px;
      }
    }
  }
}