.glasses-page {
  overflow-x: hidden;
  margin-bottom: $spacing-xxl;
}

.glasses-page-content {
  border-top: 1px solid $border-gray;
  margin-top: $spacing-xl;
  padding-top: $spacing-xl;

  @include breakpoint-m {
    padding-top: $spacing-xxl;
    padding-bottom: $spacing-xl;
  }

  .grid-column {
    margin-top: $spacing-l !important;

    @include breakpoint-m {
      margin-top: 0 !important;
    }
  }
}

.glasses-logo {
  margin-top: $spacing-l;

  @include breakpoint-m {
    margin-top: 0;
  }
}

.glasses-title-and-price {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-l;
}

.glasses-title {
  margin: 0;
}

.glasses-subtitle {
  margin: $spacing-s 0 0;
  color: $dark-gray;
}

.glasses-form {
  position: relative;
  margin: $spacing-l 0;
  border-top: 1px solid $border-gray;
  padding-top: $spacing-l + $spacing-m;

  @include breakpoint-m {
    border-top: none;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.glasses-form-select {
  margin-bottom: $spacing-m;
  min-width: 130px;

  @include breakpoint-m {
    margin-right: $spacing-m;
    margin-bottom: 0;
  }

  &.is-hidden{
    @include visually-hidden;
  }
}

.glasses-form-submit {
  width: 100%;
}

.glasses-form-button-wrapper {
  margin-top: calc(#{$spacing-l} + 5px);

  @include breakpoint-xl {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.glasses-purchase-information {
  > p {
    margin: $spacing-l 0 $spacing-s;
  }
}

.glasses-availablity {
  margin-top: $spacing-l;
}

.glasses-selling-points {
  margin: 30px 0;
  padding: 30px 0;
  border-top: 1px solid $border-gray;
  border-bottom: 1px solid $border-gray;
}

.glasses-colors {
  margin-top: $spacing-l;

  > ul {
    @include remove-list-styles;
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacing-s;
    max-width: 300px;
  }
}

.glasses-color {
  height: 24px;
  width: 34px;
  background-size: cover;
  background-position: 50% 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 1px rgba(black, 0.3);
  margin-right: $spacing-m;

  &.is-current {
    box-shadow: 0 0 0 2px black;
  }
}

.glasses-page-bottom-content {
  margin-top: $spacing-xl;

  @include breakpoint-m {
    margin-top: $spacing-xxl;
  }
}

.glasses-sidebar {
  background-color: $light-cyan;
  padding: 30px;
}

.glasses-image {
  @include responsive-margin($spacing-xl, $spacing-xxl);
}

.glasses-sidebar-wrapper {
  display: flex;
  justify-content: flex-end;
}

.glasses-tag {
  display: inline-block;
}

.glasses-form-submit {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.glasses-form-register {
  margin-top: 0;
  font-weight: bold;

  .glasses-form-submit + & {
    margin-top: 20px;
  }
  @include breakpoint-xl {
    .glasses-form-submit + & {
      margin-top: 0;
      margin-left: 20px;
      white-space: nowrap;
      
    }
  }
}

.glasses-form-submit,
.glasses-form-register {
  font-size: 14px !important;
  width: 100%;

  @include breakpoint-xl {
    white-space: nowrap;
    width: calc(50% - 10px);
  }
}

.glasses-form-purchase-information{
  margin: calc(#{$spacing-l} + 5px) 0 0;
  clear: both;
  width: 100%;

  & + .glasses-form-button-wrapper{
    margin-top: $spacing-l;
  }
}


.glasses-form-purchase-link{
  width: 100%;
  margin: 0;
}

.glasses-form-register + .glasses-form-purchase-link{
  margin-top: 20px;
  font-size: 14px;
  @include breakpoint-xl {
      margin-top: 0;
      margin-left: 20px;
  }
}

.glasses-form-actions{
  @include breakpoint-xl {
    width: 100%;
  }
}
