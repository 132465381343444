// Font
$font-size-base: 18px;
$font-stack: 'UniversLT-Condensed', sans-serif;

$orange: #f05c1f;
$cyan: #009c9e;
$dark-cyan: #007a7d;
$light-cyan: #f1f9f9;

$light-gray: #f4f4f4;
$medium-gray: #767676;
$medium-dark-gray: #4c4c4c;
$dark-gray: #2c2c2c;
$border-gray: #dddddd;
$border-gray-dark: #4a4a4a;

$dark-green: #006164;
$light-blue: #F0F2FA;
$dark-orange: #BA3A15;
$light-orange: #FDF4F1;
$light-purple: #F7F1F9;

//CTA blocks
$orange--alt: #d44218;
$clinical: #007a7d;
$blue: #1a254a;
$nude: #ffc7b3;
$mint: #8ac4c1;
$purple: #42234f;
$cold: #e6eaed;

$box-shadow: 0 2px 4px rgba(black, 0.1);

// Animation
$ease-out: cubic-bezier(0.22, 0.57, 0.25, 1);
$ease-in: cubic-bezier(0.62, 0, 0.85, 0.68);
$ease-in-out: cubic-bezier(0.65, 0.09, 0.06, 1);

// Spacing
$spacing-s: 5px;
$spacing-m: 10px;
$spacing-l: 20px;
$spacing-xl: 40px;
$spacing-xxl: 80px;

// Padding
$button-padding: 15px 25px;

// Sizing
$header-mobile-black-bar-height: 45px;
$header-content-height: 46px;
$header-bar-padding-s: 12px;
$header-bar-padding: $spacing-l;
$header-bar-height-s: $header-mobile-black-bar-height + $header-content-height +
  2 * $header-bar-padding-s;
$header-bar-height: $header-content-height + 2 * $header-bar-padding;

// Breakpoints
$breakpoint-xxs: 330px;
$breakpoint-xs: 412px;
$breakpoint-s: 480px;
$breakpoint-im: 548px;
$breakpoint-m: 700px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1360px;
$breakpoint-xxl: 1600px;

$breakpoint-xxs-max: $breakpoint-xxs - 1px;
$breakpoint-xs-max: $breakpoint-xs - 1px;
$breakpoint-s-max: $breakpoint-s - 1px;
$breakpoint-im-max: $breakpoint-im - 1px;
$breakpoint-m-max: $breakpoint-m - 1px;
$breakpoint-l-max: $breakpoint-l - 1px;
$breakpoint-xl-max: $breakpoint-xl - 1px;
$breakpoint-xxl-max: $breakpoint-xxl - 1px;

$transition--default: all 0.25s ease;
$box-shadow--button: 0 10px 10px rgba(4, 23, 23, 0.25),
  0 3px 6px rgba(0, 0, 0, 0.3);
$box-shadow--card: 0 6px 12px rgba(0, 0, 0, 0.2),
  0 20px 20px rgba(4, 23, 23, 0.25);

$button-vertical-padding: 15px;
$button-horizontal-padding: $spacing-l;
$button-padding: $button-vertical-padding $button-horizontal-padding;

$mobile-top-bar-height: 46px;