.appointment-booking-date-list {
  padding-top: 80px;

  @include breakpoint-im {
    max-width: 400px;
    margin: 0 auto;
  }

  .carousel {
    padding-bottom: 0;
  }

  .carousel-content {
    padding-top: 32px;
  }

  .carousel-items-wrapper {
    padding-top: 0 !important;
    padding-right: 15px !important;
    padding-bottom: 30px !important;
    padding-left: 15px !important;
    margin-right: -15px;
    margin-left: -15px;

    @include breakpoint-im {
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .carousel-items {

    li {
      // width: 64px !important;
      padding: 0 5px !important;

      @include breakpoint-im {
        padding: 0 8px !important;
      }
    }

    li:first-child {
      margin-left: -5px;

      @include breakpoint-im {
        margin-left: 0;
      }
    }
  }

  .carousel-prev, .carousel-next {
    top: 0;
    width: 63px;
    height: 26px;
    background-color: transparent;
    border-radius: unset;
    cursor: pointer;
    transform: none;

    @include breakpoint-im {
      top: 42px;
      width: 46px;
      height: 46px;
      border: solid 1px #78b9bb;
      border-radius: 50%;
    }

    .icon {
      width: 9px;
      height: 14px;
      color: #bac0c0;
      transition: color .25s ease;

      @include breakpoint-im {
        color: #707070;
        margin: 0 auto;
      }
    }
  }

  .carousel-next:disabled, .carousel-prev:disabled {
    opacity: 1;
    cursor: default;

    @include breakpoint-im {
      border-color: #e1e8e8;
    }
  }

  .carousel-prev:disabled {
    display: none;

    @include breakpoint-im {
      display: block;
    }
  }

  .carousel-next:not(:disabled):hover, .carousel-prev:not(:disabled):hover {

    .carousel-prev-text, .carousel-next-text, .icon {
      color: #000;
    }
  }

  .carousel-prev {
    justify-content: flex-start;

    @include breakpoint-im {
      left: -54px;
    }
  }

  .carousel-title {
    display: none;
    position: absolute;
    top: 0;
    height: 26px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #000;

    @include breakpoint-im {
      display: block;
      padding-left: 8px;
    }
  }

  .default-state .carousel-title {
    display: block;
  }

  .carousel-prev-text, .carousel-next-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-align: left;
    color: #666;
    text-transform: uppercase;
    transition: color .25s ease;

    @include breakpoint-im {
      display: none;
    }
  }

  .carousel-prev-text {
    order: 1;
    padding-left: 10px;
  }

  .carousel-next {
    justify-content: flex-end;

    @include breakpoint-im {
      right: -54px;
    }
  }

  .carousel-next-text {
    padding-right: 10px;
  }
}
