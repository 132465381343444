.product-logo {
  img {
    max-width: 60px;
    max-height: 40px;

    @include breakpoint-m {
      max-width: 100px;
      max-height: 70px;
    }
  }

  p {
    margin: 0;
  }
}
