.appointment-booking-agree-box {
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 20px;

  @include breakpoint-im {
    float: none;
    margin-top: 10px;
  }

  .checkbox {
    display: inline-block;
    vertical-align: top;
    padding-right: 10px;
  }

  .checkbox label {
    cursor: pointer;
  }

  .checkbox-fake {
    border-color: #bfdfe0;
    border-radius: 2px;
  }

  .checkbox-checkmark {
    fill: #007a7d;
  }

  &__info-icon-holder {
    position: relative;
    height: 20px;

    &:after {
      display: none;
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 1;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 8px 0 8px;
      border-color: #ffffff transparent transparent transparent;
      margin: 0 0 9px 3px;
    }

    &--checked:after {
      display: block;
    }

    &--checked svg {
      fill: #007a7d !important;
    }
  }

  &__info-icon {
    width: 20px;
    height: 20px;
    fill: #bfdfe0 !important;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      fill: #007a7d !important;
    }
  }

  &__info {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 290px;
    background-color: #fff;
    padding: 20px;
    margin: 0 0 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);

    @include breakpoint-xs {
      width: 384px;
    }
  }

  &__close-icon-holder {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  &__close-icon {
    display: block;
    width: 100%;
    height: auto;
  }

  &__info-title {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2c2c2c;
    margin: 0 0 5px;
  }

  &__info-description {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2c2c2c;
  }
}
