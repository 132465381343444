.collapsable-rich-text {
}

.collapsable-rich-text-before-enter {
  opacity: 0;
}

.collapsable-rich-text-entering {
  transition: opacity 0.2s;
  opacity: 1;
}

.collapsable-rich-text-before-leave {
  opacity: 1;
}

.collapsable-rich-text-leaving {
  transition: opacity 0.2s;
  opacity: 0;
}

.collapsable-rich-text-content {
  margin-bottom: 1rem;
}

.collapsable-rich-text-text {
}

.collapsable-rich-text-hidden-text {
  margin-bottom: 1rem;
}

.collapsable-rich-text-button {
  background: transparent;
  color: white;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &--more {
    margin-left: 0.5rem;
  }
}

.collapsable-rich-text-close-button {
  cursor: pointer;
  border: 0;
  padding: 12px;
  background: transparent;
  color: white;
  float: right;

  &:hover,
  &:focus {
    stroke: white;
  }
}

.collapsable-rich-text-close-text {
  @include visually-hidden;
}
