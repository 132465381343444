.page-header {
  padding-top: 100px;
  margin-top: -100px;
  margin-bottom: $spacing-xl;

  &--full-width-media {
    .page-header-media {
      max-width: inherit;
      margin: 0;
    }
  }
}

.page-header-image {
  .image {
    width: 100% !important; //fluid-image bugfix
  }
}

.page-header-image-caption {
  text-align: right;
  margin-top: $spacing-m;
  color: $medium-dark-gray;
}

.page-header-media {
  max-width: 900px;
  margin: 0 auto;
}
