.category-page-content-wrapper {
  background-color: $light-cyan;
  padding-top: $spacing-s;
  padding-bottom: 100px;

  @include breakpoint-m {
    margin-top: $spacing-xl;
    padding-top: $spacing-xl;
  }
}

.category-page-content {
  @include breakpoint-m {
    display: flex;
  }

  @include breakpoint-m-max {
    position: static !important;
  }
}

.category-page-pagination {
  margin: 2rem auto 0 auto;

  @include breakpoint-l {
    width: 100%;
  }
}

.category-page-sidebar {
  @include breakpoint-m {
    min-width: 200px;
    width: 33.3%;
    flex-shrink: 0;
    margin-right: $spacing-m;
  }

  @include breakpoint-l {
    width: 25%;
  }
}

.category-page-filters-wrapper {
  background-color: white;
  padding: 30px;
  box-shadow: $box-shadow;

  @include breakpoint-m-max {
    position: absolute;
    z-index: 10000;
    left: 0;
    top: $header-bar-height-s;
    padding-top: 0;
    width: 100%;
    height: calc(100vh - #{$header-bar-height-s});
    transform: translateX(-100%);
    transition: transform 0.5s $ease-out, visibility 0s 0.5s;
    overflow: auto;
    visibility: hidden;

    &.is-visible {
      transform: none;
      transition-delay: 0s;
      visibility: visible;
    }
  }
}

.category-page-filters {
  // NOTE: Copy pasted from `content-container.scss`. Find a better solution?
  @include breakpoint-m-max {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-s {
      width: 85%;
    }
  }
}

.category-page-search {
  margin-top: $spacing-l;
  margin: $spacing-xl auto 0;
  max-width: 760px;
}

.category-page-search-tabs {
  margin-top: $spacing-l;

  @include breakpoint-s {
    text-align: center;
  }
}

.category-page-search-tab {
  @include remove-button-styles;
  margin: 0 $spacing-m;
  font-weight: bold;
  color: $medium-dark-gray;

  &.is-active {
    position: relative;
    color: black;

    &:before {
      content: '';
      position: absolute;
      bottom: -$spacing-s;
      width: 100%;
      height: 4px;
      background-color: $orange;
      border-radius: 2px;
    }
  }
}

.category-page-filter-sort-container {
  margin: $spacing-l 0;
  background-color: white;
  padding: 30px;
  box-shadow: $box-shadow;

  @include breakpoint-m {
    margin: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-gray;
  }
}

.category-page-filter-sort {
  display: flex;
  width: 100%;
  margin-top: $spacing-l;

  @include breakpoint-m {
    display: block;
    width: auto;
    margin-top: 0;
  }
}

.category-page-sort {
  flex-grow: 1;
  margin-top: $spacing-l;

  @include breakpoint-s {
    flex-grow: 0;
  }
}

.category-page-news {
  margin-top: $spacing-l;
}

.category-page-show-filters {
  margin-top: $spacing-m;

  @include breakpoint-m {
    display: none !important;
  }
}

.category-page-products {
  width: 100%;
  flex-grow: 1;
  padding-bottom: $spacing-xxl;
}

.category-page-products-header {
  margin-bottom: $spacing-m;
}

.category-page-load-more {
  display: flex;
  justify-content: center;
  margin-top: $spacing-xl;
}

.category-page-articles {
  @include responsive-margin($spacing-xl, $spacing-xxl);
  border-top: 1px solid $border-gray;
  padding-bottom: $spacing-xxl;
}
