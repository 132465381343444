html {
  font-size: $font-size-base;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  @include responsive-font-size(16px, false, 18px);
  position: relative;
  line-height: 1.4;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

body,
button,
input,
textarea {
  font-family: $font-stack;
}

*:focus {
  @include focus-outline;
}

.mouse-user * {
  outline: none;
}

img {
  max-width: 100%;
}
