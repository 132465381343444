.appointment-booking-shop-list {
  padding-top: 60px;
  padding-bottom: 110px;

  @include breakpoint-im {
    padding-top: 110px;
  }

  &__inner {
    @include simple-chrome-scrollbar; // chrome fix

    $shopElementHeight: 80px;
    $numberOfShopsToDisplay: 5;

    max-height: $shopElementHeight * $numberOfShopsToDisplay +
      ($shopElementHeight / 2); // we add one half of an element for some scroll affordance
    overflow-y: auto;
  }
}
