.shape-select {
  max-width: 250px;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 50%;

    &:nth-child(odd) {
      padding-right: $spacing-s;
    }
  }
}

.shape-select-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: $spacing-s;

  html:not(.mouse-user) input:focus + label & {
    @include focus-outline;
  }

  input:checked + label & {
    .shape-select-item-checkmark {
      opacity: 1;
    }
  }

  input:disabled + label & {
    opacity: 0.5;
  }

  img {
    display: block;
    margin: $spacing-s auto 0;
  }

  &:hover {
    .shape-select-item-text {
      text-decoration: underline;
    }
  }
}

.shape-select-item-text {
  margin-top: $spacing-m;
}

.shape-select-item-checkmark {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  top: 0;
  left: 0;
  background-color: $cyan;
  color: white;
  opacity: 0;
}
