.appointment-booking-back-button {
  background: $light-cyan;
  border: 1px solid $clinical;
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
  transition: $transition--default;
  $self: &;
  min-height: 30px;

  @include breakpoint-xs {
    position: relative;
    bottom: -31px;
    z-index: 2;
  }

  &:focus-visible,
  &:hover {
    box-shadow: $box-shadow--button;
    text-decoration: underline;
    border-color: transparent;
    background-color: darken($dark-cyan, 5);
    color: white;

    #{$self}__icon,
    #{$self}__text {
      color: white;
    }

    @include breakpoint-im-max {
      text-decoration: none;
    }

  }


  @include breakpoint-im-max {
    position: fixed;
    bottom: 29px;
    left: 10px;
    z-index: 1000;
  }

  &__icon {
    color: $orange;
    display: block;
    width: 18px;
    height: 12px;
    align-self: center;
    margin-right: 10px;
  }

  &__text {
    align-self: center;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 14px;
    line-height: 11px;
    color: black;
    display: block;
    height: 11px;
    margin-top: -1px;

    @-moz-document url-prefix() {
      margin-top: -2px;
    }

    &--in-safari{
      margin-top: 0;
    }

    &--in-android{
      margin-top: 2px;
    }
  }
}