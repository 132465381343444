.magazine-category {
  padding: 5px 10px;
  background: $light-orange;
  @include responsive-font-size(14px, false, 16px);
  text-transform: uppercase;
  color: $dark-orange;
  line-height: 1.14;
  
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;

  @include breakpoint-m {
    line-height: 1.125;
    top: 17px;
    left: 17px;
  }
}