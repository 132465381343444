.appointment-booking-see-nearest {
  position: fixed;
  right: 0;
  bottom: 24px;
  left: 0;

  @include breakpoint-im-max {
    bottom: 80px;
  }

  &__button {
    position: relative;
    display: flex !important;
    align-items: center;
    padding: 15px 14px 15px 36px !important;
    margin: 0 auto;

    @include breakpoint-im {
      height: auto !important;
      padding: 22px 25px 22px 60px !important;
    }

    &:hover {
      text-decoration: none !important;
      background-color: #007a7d !important;
      box-shadow: 0 5px 8px 0 rgba(71, 93, 92, 0.5) !important;
    }
  }

  &__icon-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 36px;
    justify-content: center;
    align-items: center;

    @include breakpoint-im {
      width: 60px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: #f1f9f9;

    @include breakpoint-im {
      font-size: 21px;
    }
  }
}
