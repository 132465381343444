.product-variant-text {
  padding: 30px 0 0;

  *{
      margin-top: 0;
      margin-bottom: 0;
  }

  &--in-contact-lens{
    background-color: $light-cyan;
    padding: 30px;

    
  }
}