.component-list-item {
  width: 100%;
  margin-top: 40px;

  @include breakpoint-m {
    margin-left: $spacing-m;
  }

  > * {
    // Ensures that floating styles in blocks don't cause problems with other blocks. Doing this on the direct children of .component-list-item allows individual blocks to override this behaviour.
    clear: both;
  }

  &-fallback {
    border: 1px solid $orange;
    padding: $spacing-l;
  }

  &.cyan {
    padding: 2rem 0;
    background-color: $light-cyan;
  }

  &.size-full {
    width: 100%;
  }

  &.size-article {
    @include default-content-width;
  }

  &.size-half {
    @include breakpoint-m {
      width: calc(50% - #{$spacing-m});
    }
  }

  &.size-third {
    // @include breakpoint-m {
    //   width: calc(50% - #{$spacing-m});
    // }

    @include breakpoint-m {
      width: calc(33.33333% - #{$spacing-m});
    }
  }
}
