.clickable {
  display: inline-block;
  text-decoration: none;
  appearance: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: none;

  &.theme-arrow-right {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      text-decoration: underline;
    }

    .clickable-icon {
      position: relative;

      color: $orange;
      flex: 0 0 auto;
      margin-left: $spacing-l;
    }
  }

  &.theme-arrow-right.theme-button-white {
    &:hover {
      .clickable-icon {
        color: white;
      }
    }
  }

  &.has-icon-before,
  &.has-icon-after {
    display: inline-flex;
    align-items: flex-start;

    .clickable-icon {
      position: relative;
      top: 0.4em;
      transform: translateY(-50%);
    }
  }

  &.has-icon-after {
    .clickable-icon {
      margin-left: $spacing-m;
    }
  }

  &.has-icon-before {
    .clickable-icon {
      margin-right: $spacing-m;
    }
  }

  &.theme-button-cyan,
  &.theme-button-cyan-small,
  &.theme-button-white,
  &.theme-button-gray,
  &.theme-button-outline,
  &.theme-button-gray {
    @include responsive-font-size(14px, false, 16px);
    @include button-common;
    cursor: pointer;
    padding: $button-padding;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    align-items: center;
    justify-content: center; // NOTE: Used when button has icon
    transition: $transition--default;

    &:hover {
      cursor: pointer;
      box-shadow: $box-shadow--button;
      text-decoration: underline;
    }
  }

  &.theme-button-cyan,
  &.theme-button-cyan-small {
    background-color: $dark-cyan;
    color: white;

    &:hover {
      background-color: darken($dark-cyan, 5);
    }
  }

  &.theme-button-cyan-small {
    padding: 15px;
  }

  :root &.theme-button-cyan-light {
    justify-content: space-between;
    background-color: $light-cyan;
    color: $dark-cyan;
    padding: $button-padding;
    transition: $transition--default;

    &:hover {
      background-color: darken($dark-cyan, 5);
      color: white;
      box-shadow: $box-shadow--button;
      text-decoration: underline;
    }
  }

  &.theme-button-outline {
    border: 1px solid black;

    &:hover {
      border-color: transparent;
      background-color: darken($dark-cyan, 5);
      color: white;

      .clickable-icon {
        color: white;
      }
    }
  }

  &.theme-button-white {
    background-color: white;
    color: black;

    &:hover {
      background-color: darken($dark-cyan, 5);
      color: white;
    }
  }

  &.theme-button-gray {
    background-color: $dark-gray;
    color: white;
    font-weight: bold;

    &:hover {
      background-color: darken($dark-cyan, 5);
    }
  }

  &.theme-circle,
  &.theme-circle-dark,
  &.theme-circle-small {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 46px;
    border-radius: 100%;
    background-color: $light-gray;
    color: black;

    &:hover {
      background-color: $border-gray;
    }

    svg {
      display: block;
    }
  }

  &.theme-circle-dark {
    background-color: $dark-gray;
    color: white;
  }

  &.theme-circle-small {
    height: 24px;
    width: 24px;
  }

  &.theme-link {
    .clickable-children {
      color: $dark-cyan;
      border-bottom: 2px solid $cyan;
      line-height: 1;
    }

    &:hover {
      .clickable-children {
        color: darken($dark-cyan, 5);
        border-bottom-color: transparent;
      }
    }
  }

  &.theme-link-big,
  &.theme-link-big-uppercase {
    @include responsive-font-size(18px, false, 21px);
  }

  &.theme-link-big-uppercase {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid;
    line-height: 1;

    &:hover {
      border-color: transparent;
    }
  }

  &.theme-link-gray {
    color: $medium-gray;

    &:hover {
      text-decoration: underline;
    }
  }

  &.theme-link-gray-underline {
    color: $medium-gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.theme-link-white {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.theme-uppercase {
    @include responsive-font-size(14px, false, 16px);
    text-transform: uppercase;
    line-height: 1.1;

    &:hover {
      text-decoration: underline;
    }
  }

  &.theme-plain {
    @include responsive-font-size(14px, false, 16px);
    text-transform: uppercase;
    line-height: 1.1;
  }

  &.theme-wide {
    display: flex;
    justify-content: center;
    width: calc(100% - #{$button-horizontal-padding * 2});
  }
}

.clickable-children {
  line-height: 1;
}
