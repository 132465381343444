.selling-points {
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
}

.selling-points-item {
  width: 33.3%;
  max-width: 120px;
  text-align: center;
  margin: 0 $spacing-s;
  text-decoration: none;
  line-height: 1;
  color: inherit;
}

.selling-points-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 45px;
  width: 45px;
  margin: 0 auto $spacing-m;
}

.selling-points-text {
  display: inline;
  border-bottom: 1px solid transparent;

  a.selling-points-item:hover & {
    border-bottom-color: currentColor;
  }
}
