.reserve-stores-list {
  &__item{
    border-bottom: 1px solid black;
    padding: 30px 0;

    &-heading{
      margin: 0;
      @include responsive-font-size(18px, false, 21px);
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint-m {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__store-data{
    margin-bottom: 5px;

    @include breakpoint-m {
      margin-bottom: 0;
      margin-right: 10px;
    }

    table{
      border-collapse: collapse;
      border: none;
    }
    th,
    td{
      border-collapse: collapse;
      padding-bottom: 15px;
    }
  }

  &__store-term{
    text-align: left;
    padding-right: 10px;
    font-weight: normal;
    vertical-align: top;
    white-space: nowrap;

    &:after{
      content: ":";
    }
  }

  &__store-hours{
    &.bottom-margin.rich-text{
      &,
      p{
        margin: 0;
      }
    }
  }

  &__status{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    align-self: flex-start;

    @include breakpoint-m {
      margin-bottom: 30px;
      align-self: flex-end;
    }

    &-container{
      display: flex;
      flex-direction: column;

      @include breakpoint-m {
        flex: 0 0 calc(50% - 10px);
        max-width: 283px;
      }
    }

    &-icon{
      align-self: flex-start;
      margin-right: 10px;
      flex: 0 0 30px;
    }
    &-text{
      align-self: center;
    }
  }

  &__reserve-form {
    display: none;
    &--is-shown{
      display: block;
    }
  }

  &__reserve-button{
    @include breakpoint-m {
      align-self: flex-end;
    }
  }
}
