.image-link-image {
  position: relative;
  display: block;
  flex-shrink: 0;
  overflow: hidden;
}

.image-link-image-inner {
  display: block;
  transition: transform 0.8s $ease-out;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (3 / 4) * 100%;
  }
}

.image-link-icon {
  margin-left: auto;
  margin-top: -4px;

  @include breakpoint-m {
    margin-left: $spacing-l;
  }
}

.image-link-cta {
  @include responsive-margin($spacing-m, false, 30px);
  display: flex;
  justify-content: center;
}

.image-link-cta-text {
  @include responsive-font-size(14px, false, 16px);
  text-transform: uppercase;
  line-height: 1.1;
}

.image-link-cta-icon {
  position: relative;
  color: $orange;
  margin-left: $spacing-l;
  top: 0.4em;
  transform: translateY(-50%);
}

.image-link-link {
  position: relative;
  display: block;
  height: 100%;
  text-decoration: none;
  color: inherit;
  transition: $transition--default;
  padding-bottom: 20px;

  &:hover {
    box-shadow: $box-shadow--card;

    .image-link-list-cta-text {
      text-decoration: underline;
    }

    .image-link-list-text--only {
      text-decoration: underline;
    }

    .image-link-list-image-inner {
      transform: scale(1.05);
    }
  }
}

.image-link-content {
  padding: 0 $spacing-m;
}

.image-link-big-text {
  width: 100%;
  text-align: center;
}

.image-link-text {
  @include responsive-margin($spacing-l, false, 30px);
  display: block;
  width: 100%;
  text-align: center;

  svg {
    color: $orange;
    margin-left: $spacing-l;
  }
}

.image-link-ingress {
  @include responsive-margin($spacing-m, false, $spacing-l);
  display: block;
  transform: none !important;
  margin-left: auto;
  margin-right: auto;
  color: $dark-gray;
  text-align: center;

  @include breakpoint-s {
    width: 80%;
  }
}
