.image-and-products {
  background-color: $light-cyan;
  padding: $spacing-l 0;
  box-shadow: -$spacing-xxl 0 0 0 $light-cyan, $spacing-xxl 0 0 0 $light-cyan;

  @include breakpoint-l {
    padding: $spacing-xl 0;
  }
}

.image-and-products-content {
  display: flex;

  &.theme-align-right {
    @include breakpoint-l {
      flex-direction: row-reverse;

      .image-and-products-products {
        padding-left: 0;
        padding-right: $spacing-m;
      }
    }
  }
}

.image-and-products-title {
  text-align: center;
}

.image-and-products-image {
  position: relative;
  padding-top: 100%;

  @include breakpoint-l {
    width: 50%;
    flex-shrink: 0;
    margin-bottom: $spacing-m;
    padding-top: 0;
  }

  .image-and-products.has-single-product & {
    @include breakpoint-l {
      margin-bottom: 0;
    }
  }

  .image-and-products.has-single-product & {
    padding-top: 50%;
  }
}

.image-and-products-products {
  margin-top: $spacing-m;

  @include breakpoint-l {
    width: 50%;
    padding-left: $spacing-m;
    margin-top: 0;
  }

  .image-and-products.has-single-product & {
    display: flex;
  }
}

.image-and-products-product {
  display: flex;
  height: 100%;
  width: 100%;
  padding: $spacing-l $spacing-m;

  @include breakpoint-m {
    padding: $spacing-l $spacing-xl;
  }

  @include breakpoint-xl {
    padding: $spacing-l 50px;
  }
}
