.category-filters {
  > h2 {
    @include visually-hidden;
  }
}

.category-filters-nav {
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 0;
  padding: $spacing-l 0;
  background-color: $light-gray;
  border-bottom: 1px solid $border-gray;

  @include breakpoint-m {
    display: none;
  }

  .category-filters.is-visible & {
  }
}

.category-filters-hide-wrapper {
  width: 100%;
  margin-bottom: $spacing-l;
}

.category-filters-hide {
  svg {
    color: $orange;
  }
}

.category-filters-apply {
  margin-right: $spacing-m;
}

.category-filters-content {
  padding-top: 160px;
  padding-bottom: $spacing-l;

  @include breakpoint-m {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.category-filters-submit {
  @include non-js-reveal;
  margin-top: $spacing-xl;

  &.is-mounted {
    visibility: hidden;
    position: absolute;
  }
}

.category-filters-reset {
  &.is-hidden {
    visibility: hidden;
    position: absolute;
  }
}
