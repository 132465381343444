/* IE and EDGE */
.appointment-booking-progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  position: absolute;
  top: 66px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  width: 112px;
  height: 9px;
  color: rgb(241, 92, 33);
  background-color: #f1f9f9;
  border: 1px solid #f15c21;
  border-radius: 0;
  margin: 0 auto;

  @include breakpoint-im {
    top: 58px;
  }
}

/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
.appointment-booking-progress::-webkit-progress-bar {
  background-color: #f1f9f9;
  border-radius: 0;
}

.appointment-booking-progress::-webkit-progress-value {
  background-color: rgb(241, 92, 33);
  border-radius: 0;
  transition: width 1s ease;
}

/* MOZILLA FIREFOX */
.appointment-booking-progress::-moz-progress-bar {
  background-color: rgb(241, 92, 33);
  border-radius: 0;
}
