.terms-form-terms,
.terms-form-delivery {
  max-width: 500px;
}

.terms-form__marketing {
  margin-bottom: 1rem;
}

.terms-form__legal,
.terms-form__privacy {
  margin-bottom: 2rem;
}

.terms-form__description {
  background-color: $light-cyan;
  padding-left: $spacing-l;
  padding-right: $spacing-l;

  > div {
    &:first-child,
    &:nth-child(2) {
      padding-top: $spacing-l;
    }

    &:last-child {
      padding-bottom: $spacing-l;
    }
  }
}

.terms-form__reminders {
}

.terms-form__reminder {
  margin-bottom: $spacing-m;
}

.terms-form__marketing {
  background-color: $light-cyan;
  padding: $spacing-l;
}

.terms-form__heading {
  margin: 0 0 $spacing-m 0;
  font-weight: bold;
  font-size: 1rem;
}

.terms-form__marketing-text {
  margin-top: 1rem;
}

.terms-form-delivery {
  margin-top: $spacing-xl;
}

.terms-form-checkbox {
  margin-top: $spacing-l;

  &.is-required {
    font-weight: bold;
  }
}

.terms-form-link {
  display: inline-block;
  margin-top: $spacing-s;
}

.terms-form-error {
  padding-bottom: $spacing-xl;

  p {
    background-color: rgba($orange, 0.2);
    padding: $spacing-l;
    color: black;
    font-weight: 300;
    margin: 0;
  }
}

.terms-form-radio {
  & + & {
    margin-top: $spacing-l;
  }
}

.terms-form-label,
.terms-form-label-description {
  display: block;
  color: $dark-gray;
}

.terms-form-label {
  font-weight: bold;
  line-height: 1;
}

.terms-form-label-description {
  margin-top: $spacing-s;
}

.terms-form-submit {
  margin-top: $spacing-xl;
}
