.product-list-block {
  background-color: $light-cyan;
  padding: $spacing-l 0;
  box-shadow: -$spacing-xxl 0 0 0 $light-cyan, $spacing-xxl 0 0 0 $light-cyan;

  @include breakpoint-l {
    padding: $spacing-xl 0;
  }
}

.product-list-block-title {
  text-align: center;
  margin: 0;
}

.product-list-block-products {
  @include responsive-margin($spacing-l, false, $spacing-xl);
}
