.my-store {
  &__selected {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: $clinical;
  }

  &__selected-icon {
    color: white;
  }

  &__selected-text {
    margin-left: 0.5rem;
    color: white;
  }

  &__toggle {
    margin-top: 1rem;
  }

  &__text-input {
    margin-bottom: 1rem;
    display: flex;
  }

  &__button {
    font-size: 0.9rem;
  }
}
