.article-page {
  $self: &;
  $narrow-width: 65%;

  &__content {
    @include responsive-margin($spacing-xl, false, $spacing-xl);
    margin-bottom: $spacing-xxl;

    @include breakpoint-l {
      max-width: 1220px;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
    }
  }

  &__lead {
    @include breakpoint-l {
      width: $narrow-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__ingress {
    margin-bottom: 60px;
  }

  &__main-column {
    width: 100%;
  }

  &__side-column {
    @include breakpoint-l {
      width: 35%;
      max-width: 450px;
      min-width: 300px;
    }
  }

  &--sidebar {
    #{$self}__lead {
      width: 100%;
    }

    #{$self}__main-column {
      @include breakpoint-l {
        padding-right: 8%;
        width: $narrow-width;
      }
    }
  }
}
