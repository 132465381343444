.site-info-banner {
  position: relative;

  @include breakpoint-l-max {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}

.site-info-banner-before-enter {
  transition: transform 0.3s linear;
  transform: translateY(0);
}

.site-info-banner-entering {
  transform: translateY(0);
}

.site-info-banner-before-leave {
  transition: transform 0.3s linear;
  transform: translateY(0);
}

.site-info-banner-leaving {
  transform: translateY(-100%);
  position: absolute;
}

.site-info-banner-wrapper {
  background-color: $clinical;
  color: white;
  padding: 20px;
  z-index: 1000;
  width: 100%;

  border-top: 4px solid $orange;
  border-bottom: 4px solid $orange;

  @include breakpoint-l-max {
    min-height: 130px;
  }
}

.site-info-banner-content {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.site-info-banner-actions {
  margin-top: 20px;
}

.site-info-banner-header {
  margin: 0 0 $spacing-s 0;
  font-size: 1rem;
}

.site-info-banner-close {
  position: absolute;
  top: 0;
  right: 0;
  
  @include breakpoint-l-max {
    top: -10px;
    right: 3px;
  }
}

.site-info-banner-close-button {
  cursor: pointer;
  border: 0;
  padding: 12px;
  background: transparent;
  color: white;

  &:hover,
  &:focus {
    stroke: white;
  }
}

.site-info-banner-close-text {
  @include visually-hidden;
}
