.contact-lens-page {
  margin-bottom: $spacing-xxl;
}

.contact-lens-form {
  &--no-section-margins {
    .contact-lens-form-section {
      margin-bottom: 0;
    }
  }
}

.contact-lens-title {
  > p {
    margin: $spacing-s 0 0;
  }

  h1 {
    margin-top: $spacing-s;
  }
}

.contact-lens-logo {
  margin-top: $spacing-l;

  @include breakpoint-m {
    margin-top: 0;
  }
}

.contact-lens-tag-wrapper {
  margin-bottom: $spacing-l;
  display: flex;
  justify-content: flex-end;
}

.contact-lens-tag {
}

.contact-lens-product-selling-points {
  @include responsive-margin($spacing-xl, false, $spacing-xxl);
  max-width: 700px;
}

.contact-lens-image {
  @include responsive-margin($spacing-l, $spacing-xl);
}

.contact-lens-form {
  position: relative;
  margin: $spacing-l 0 0;

  @include breakpoint-m {
    margin-top: 0;
  }

  @include breakpoint-l {
    display: block;
  }

  h2 {
    margin: 0;
  }
}

.contact-lens-form-section {
  background-color: $light-cyan;
  padding: 30px;
  margin-bottom: $spacing-s;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.contact-lens-form-subtitle {
  color: $dark-gray;
  margin: $spacing-s 0 0;
}

.contact-lens-product-select,
.contact-lens-property-select {
  @include breakpoint-m {
    width: 100%;
  }
}

.contact-lens-product-select {
}

.contact-lens-product-select-item {
  &:not(:last-child) {
    padding-bottom: $spacing-m;
    margin-bottom: 15px;
    border-bottom: 1px solid $border-gray;
  }
}

.contact-lens-property-select {
  margin: $spacing-xl auto 0 auto;
}

.contact-lens-availability {
  margin-top: $spacing-l;
}

.contact-lens-form-price {
  line-height: 1.15;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.contact-lens-form-summary {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 30px;
  border-top: 1px solid $border-gray;
  margin: $spacing-l 0 $spacing-xl;
  width: 100%;
}

.contact-lens-content {
  border-top: 1px solid $border-gray;
  margin-top: $spacing-xl;
  padding-top: $spacing-xl;

  @include breakpoint-m {
    padding-top: $spacing-xxl;
  }

  > h2 {
    margin: 0;
    margin-bottom: $spacing-xl;
  }
}

.contact-lens-information {
  h3 {
    margin: 0 0 $spacing-l;
  }

  p {
    margin: $spacing-s 0 $spacing-l;
  }
}

.contact-lens-metadata {
  h3 {
    margin: 0 0 $spacing-l;
  }

  td {
    vertical-align: top;
  }

  td:first-child {
    padding-right: $spacing-l;

    @include breakpoint-m {
      padding-right: $spacing-xl;
    }
  }
}

.contact-lens-product-wrapper {
  @include breakpoint-l {
    display: flex;
    justify-content: space-between;
  }
}

.contact-lens-main-product {
  width: 100%;

  .contact-lens-replacement-product + & {
    display: flex;
    flex-direction: column;
    background-color: $light-cyan;
    padding: $spacing-l $spacing-l $spacing-m;
    margin-top: $spacing-m;

    @include breakpoint-l {
      width: calc(50% - #{$spacing-s});
      padding: $spacing-xl $spacing-l $spacing-m;
      margin-top: 0;
    }

    h2 {
      margin: 0;
    }

    .contact-lens-product-selling-points {
      margin-top: $spacing-l;

      @include breakpoint-l-max {
        display: none;
      }
    }

    .contact-lens-logo {
      display: none;
    }

    .contact-lens-image {
      background-color: white;
      padding: $spacing-xl $spacing-m $spacing-l;
      margin-top: $spacing-xl;
      margin-left: -$spacing-m;
      margin-right: -$spacing-m;

      @include breakpoint-l {
        min-height: 200px;
        margin-top: 0;
      }
    }
  }
}

.contact-lens-purchase-information {
  margin-top: $spacing-xl;
}

.contact-lens-replacement-product {
  @include breakpoint-l {
    width: calc(50% - #{$spacing-s});
  }
}

.contact-lens-shipping-banner {
  margin: $spacing-xl 0;
  display: flex;

  p {
    margin: 0;
  }
}

.contact-lens-availability-banner {
  display: flex;
  margin-top: $spacing-l;
  padding: $spacing-l;
  background-color: $orange;

  p {
    color: white;
    margin: 0;
  }
}

.contact-lens-availability-banner-icon {
  margin-right: $spacing-m;
  margin-top: 0.3em;
  flex-shrink: 0;
  color: $orange;
}

.contact-lens-selling-points {
  position: relative;
  background-color: $light-cyan;
  padding: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background-color: $border-gray;
  }
}

.contact-lens-blocks {
  @include responsive-margin($spacing-xl, false, $spacing-xxl);
}

.contact-lens-form-quantity {
  display: inline-block;
  margin-top: $spacing-xl;
}
