.search {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.search-input {
  width: calc(100% + #{$spacing-s});
  margin-right: -$spacing-s;

  &.text-input label{
    position: absolute;
    bottom: 100%;
  }

  .search--reserv-in-store &{
    border: 1px solid #4A4A4A ;
  }
}

.search-button-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  background-color: $light-gray;
  color: $dark-cyan;
}

.search-button {
  height: 100%;
  padding: 15px 18px;

  .theme-big & {
    @include breakpoint-s {
      padding: 15px 25px;
    }
  }

  &--with-text{
    min-width: 123px;
  }
}
