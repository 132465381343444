.pagination-controls {
  &__list {
    @include breakpoint-l {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: -0.25rem;
      margin-top: -0.5rem;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-left: 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__pages-container {
    margin: 1rem 0;

    @include breakpoint-l {
      margin: 0 2rem;
    }
  }

  &__pages-list {
    @include remove-list-styles;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -0.25rem;
    margin-top: -0.5rem;

    @include breakpoint-l {
      justify-content: center;
    }
  }

  &__page-item {
    display: flex;
    align-items: center;

    margin-top: 0.5rem;
    margin-left: 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__page-link {
    position: relative;
    border: 1px solid black;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    color: black;

    &--disabled {
      background-color: black;
      color: white;
    }

    &:hover:not(.pagination-controls__page-link--disabled) {
      border-color: transparent;
      background-color: #006164;
      color: white;
    }
  }

  &__page-link-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__browse-link {
    @include responsive-font-size(14px, false, 16px);
    @include button-common;
    background-color: white;
    color: black;
    text-decoration: none;

    display: flex;
    align-items: center;

    &:hover:not(.pagination-controls__browse-link--disabled) {
      box-shadow: $box-shadow--button;
      background-color: darken($dark-cyan, 5);
      color: white;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__link-text {
    display: inline-block;
    margin: 0 10px;
  }
}
