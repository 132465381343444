.appointment-booking-result-time {
  position: relative;
  padding: 9px 45px 10px 10px;
  border: 1px solid #007a7d;
  border-radius: 2px;
  cursor: pointer;

  @include breakpoint-xs {
    padding: 9px 45px 10px 41px;
  }

  @include breakpoint-im {
    padding: 9px 60px 10px 50px;
  }

  .step-5 & {
    padding-right: 10px;
    border-color: #bfdfe0;
    cursor: default;
  }

  &__time-box {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: #007a7d;
    padding: 0 12px;

    @include breakpoint-xs {
      display: flex;
    }

    @include breakpoint-im {
      padding: 0 16px;
      width: 50px;
    }
  }

  &__title {
    display: inline;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c2c;
  }

  &__text-lowercase {
    text-transform: lowercase;
  }

  &__description {
    display: inline;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #707070;
  }

  &__edit-box {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    display: flex;
    width: 45px;
    justify-content: center;
    align-items: center;
    color: #979797;
    padding: 0 10px 0 14px;
    border-left: 1px solid #bfdfe0;

    @include breakpoint-im {
      width: 60px;
      padding: 0 19px 0 20px;
    }

    .step-5 & {
      display: none;
    }

    & > * {
      width: 100%;
      transition: all .25s ease;
    }
  }

  &:hover .appointment-booking-result-time__edit-box {
    color: #2c2c2c;
  }
}
