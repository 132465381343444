.price {
  text-align: right;

  &.theme-line-through {
    text-decoration: line-through;
  }

  &.theme-vertical {
    display: flex;
    flex-direction: row-reverse;

    .price-small {
      margin-top: 0;
      margin-right: $spacing-l;
    }
  }

  &.theme-big {
    .price-big {
      @include responsive-font-size(20px, false, 27px);
    }
  }
}

.price-small {
  line-height: 1;
  text-decoration: line-through;
  color: $medium-gray;
  margin-left: auto;
  white-space: nowrap;

  .price-big + & {
    margin-top: $spacing-s;
  }
}

.price-big {
  margin-left: auto;
  white-space: nowrap;
  line-height: 1;

  .price-small + & {
    margin-left: $spacing-m;

    @include breakpoint-l {
      margin-left: $spacing-l;
    }
  }
}

.price-label {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 0.8em;
  margin-right: $spacing-s;

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: currentColor;
  }
}
