.cart {
  overflow: hidden;

  &.theme-mini {
    // Add 100px extra bottom padding to fix iOS Safari bottom toolbar covering the purchase button
    padding: $spacing-xl 0 (100px + $spacing-xl);

    @include breakpoint-m {
      padding: $spacing-xl + $spacing-l;
      padding-top: $spacing-xl;
    }
  }
  &.theme-maxi{
    margin-bottom: 20px;
  }
}

.cart-number-of-products {
  font-weight: bold;
  margin: 0;
  margin-bottom: $spacing-m;
}

.cart-products {
  width: 100%;
  border-top: 1px solid $border-gray;
  border-bottom: 1px solid $border-gray;

  @include breakpoint-m {
    padding: $spacing-l 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}

.cart-summary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $border-gray;
  padding: $spacing-l 0;
  margin-left: auto;
  margin-top: -1px;

  & ~ .cart-summary {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: 2px solid $border-gray;
  }

  &.not-editable {
    @include breakpoint-m {
      padding-right: 0px;
    }
  }

  @include breakpoint-m {
    padding-right: 50px;
  }

  .theme-maxi & {
    @include breakpoint-m {
      max-width: 520px;
    }
  }

  > p {
    font-weight: bold;
    margin: 0;
    line-height: 1;
  }

  .cart-discount-text {
    width: 100%;
    margin-top: $spacing-m;

    @include breakpoint-m {
      padding-right: 40%;
    }
  }
}

.cart-children {
  margin-top: $spacing-xl;
}

.cart{
  &__discounts-list{
    list-style: none;
    margin: $spacing-m 0 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
  &__discounts-list-item{
    clear: both;
    margin-top: $spacing-m;
  }

  &__discounts-list-wrapper{
    width: 100%;
  }
}
