.color-select {
  max-width: 200px;

  @include breakpoint-m {
    max-width: none;
  }

  input {
    @include visually-hidden;

    html:not(.mouse-user) &:focus + label {
      @include focus-outline;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.color-select-item {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: $spacing-m;
  margin-top: $spacing-m;
  margin-bottom: $spacing-m;
  border-radius: 50%;
  transform: scale(0.75);
  background-size: cover;
  background-position: center center;

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input[disabled] + label & {
    opacity: 0.3;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      left: 0;
      background-color: white;
    }

    &:before {
      transform: translate(0, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(0, -50%) rotate(-45deg);
    }
  }

  input:checked + label & {
    transform: none;

    svg {
      display: block;
    }
  }
}

.color-select-item-text {
  @include visually-hidden;
}
