.cart-product-status {
  justify-content: flex-start !important;
  font-weight: normal;
  padding: 0 !important;

  :root & td {
    padding-top: $spacing-l + $spacing-m;
  }
}

.cart-product-status-text {
  &:not(:empty) {
    margin-right: $spacing-l;
  }
}
