$opticiansListGutter: 2rem;
$opticiansNumberOfColumns: 4;

.opticians-list-heading {
  text-align: center;
  margin-bottom: 2rem;
}

.opticians-list-list {
  @include remove-list-styles;

  @include breakpoint-m {
    margin-left: -$opticiansListGutter;
    margin-top: -$opticiansListGutter;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.opticians-list-item {
  margin-bottom: 2rem;

  p {
    margin: 0;
  }

  @include breakpoint-m {
    margin-left: $opticiansListGutter;
    margin-top: $opticiansListGutter;
    margin-bottom: 0;
    flex: 0 0
      calc(100% / #{$opticiansNumberOfColumns} - #{$opticiansListGutter});
  }
}
