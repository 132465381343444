.product-image-container {
  position: relative;
  width: 100%;

  > img {
    margin: 0 auto;
  }
}

.product-image-hover-icon {
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -28px;
  margin-left: -28px;
  height: 56px;
  width: 56px;
  background-color: black;
  color: white;
  opacity: 0;
  border-radius: 100%;
  transform: scale(0.9);
  transition: transform 0.3s $ease-out, opacity 0.3s $ease-out;

  .product-image-container:hover & {
    opacity: 0.75;
    transform: none;
  }

  &:hover {
    opacity: 1 !important;
  }

  html:not(.touchevents) & {
    display: flex;
  }
}

.product-image-zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;

  &.before-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.entering,
  &.before-leave {
    opacity: 1;
    transform: scale(1);
  }

  &.leaving {
    opacity: 0;
    transform: scale(0.8);
  }

  &.entering,
  &.before-leave {
    transition: transform 0.5s $ease-out, opacity 0.5s $ease-out;
  }

  img {
    display: block;
    flex-shrink: 0;
    max-width: none;
    height: auto;
    will-change: transform;
  }
}

.product-image-zoom-close {
  position: absolute;
  top: $spacing-xl;
  right: $spacing-xl;
  padding: 15px;

  svg {
    vertical-align: top;
  }
}

.product-image-zoom-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
