.category-link {
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: $medium-dark-gray;
  text-decoration: none;
}

.category-link-image {
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  height: 80px;
  width: 80px;
  margin-right: $spacing-m;
}
