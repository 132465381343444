.optician {
  @include responsive-font-size(14px, false, 16px);
  height: 100%;
}

.optician-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.optician-title,
.optician-body-text,
.optician-button {
  margin: 0.5rem 0 0 0;
}

.optician-title-text {
  margin: 0;
}

.optician-link {
  margin-top: 1rem;
}
