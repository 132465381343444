.delivery-widget {
  &__error {
    display: flex;
    justify-content: center;
  }

  &__error-text {
    padding: 1rem;
    background-color: $clinical;
    color: white;
    border: 1px solid rgb(222, 218, 215); //matches Klarna-iframe
  }
}
