.magazine-footer {
    background-color: $light-orange;
    padding: 50px 0 30px;

    &__inner {
        display: flex;
        flex-direction: column;

        @include breakpoint-m {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__column {
        word-break: break-word;
        
        margin-bottom: 20px;

        &--3 {
            margin-bottom: 0;
        }

        @include breakpoint-m {
            max-width: calc(100% / 3);
            flex: calc(100% / 3);
            margin-bottom: 0;
            padding-right: 56px;
            line-height: 1;

            &--3 {
                margin-right: 0;
            }
        }
    }

    &__logo {
        width: 100%;
        max-width: 287px;
        margin-bottom: 40px;

        @include breakpoint-m {
            margin-top: 10px;
            margin-bottom: 50px;
        }
    }

    &__back-link {
        margin-top: 40px;

        @include breakpoint-m {
            margin-top: 60px;
        }
    }
}