.collapse-list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding-bottom: $spacing-s;
  }

  > button {
    @include remove-button-styles;
    color: $dark-gray;
    font-size: inherit;
    margin-top: $spacing-m;

    span {
      border-bottom: 1px solid currentColor;
    }

    &:hover {
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
