.newsletter-form-block {
    background: $dark-gray;
    padding: 10% $spacing-l;

    @include breakpoint-s {
        padding: 10% $spacing-xl;
    }

    &.theme-compact {
        padding: 5%;
    }

    &.theme-full {
        @include breakpoint-m {
            padding: 10% $spacing-xxl;
        }
    }

    &.theme-small {
        @include breakpoint-xl {
            padding: 10% $spacing-xl;
        }
    }

    &--mint {
        color: $clinical;
        background-color: $mint;
    }

    &--blue {
        background-color: $blue;
    }

    &--cyan {

        background-color: $dark-cyan;
    }

    &--orange {
        background-color: $orange--alt;
    }

    &--cold {
        background-color: $cold;
    }

    &--clinical {
        background-color: $clinical;
    }

    &--nude {
        background-color: $nude;
    }

    &--purple {
        background-color: $purple;
    }

    &--gray {
        background-color: $dark-gray;
    }
}