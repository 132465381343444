.photo-block {
    margin-top: 10px;
    margin-bottom: 10px;

    @include breakpoint-m {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__photos {
        display: flex;
        flex-direction: column;

        @include breakpoint-m {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: column;

        &--half {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include breakpoint-m {
            &--half {
                flex: calc(50% - 5px);
                width: calc(50% - 5px);
                margin-right: 10px;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__image {
        &-container {
            width: 100%;
            position: relative;

            &:after {
                display: block;
                content: "";
            }

            &--format-square:after {
                padding: 0 0 98.5%;
            }

            &--format-square-big:after {
                padding: 0 0 75%;
            }

            &--format-landscape:after {
                padding: 0 0 48.89%;
            }

            &--format-tall:after {
                padding: 0 0 151.11%;
            }
        }

        &-credit-text {
            align-self: flex-end;
            @include image-credit-text;
        }

        &-caption {
            margin-top: 11px;
            text-align: center;
            padding: 0 10px;
            @include responsive-font-size(16px, false, 18px);
            line-height: 1.375;

            @include breakpoint-m {
                text-align: left;
                margin-top: 17px;

                line-height: 1.44;
            }

            @include breakpoint-l {
                padding-left: 50px;
            }
        }
    }
}