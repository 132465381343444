.grid {
  @include breakpoint-l {
    display: flex;
    justify-content: space-between;
  }

  &.theme-centered {
    @include breakpoint-l {
      justify-content: center;
    }

    .grid-column:only-child {
      padding: 0;
    }
  }

  &.theme-narrow {
    @include breakpoint-l {
      max-width: 1220px;
      margin: 0 auto;
    }
  }
}

.grid-column {
  &:nth-child(2) {
    margin-top: $spacing-xxl;

    @include breakpoint-l {
      margin-top: 0;
    }
  }

  .theme-compact & {
    &:nth-child(2) {
      @include responsive-margin($spacing-xl, false, 0);
    }
  }

  .theme-no-margin & {
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  @include breakpoint-l {
    width: 47%;

    .theme-right-column-wider > & {
      &:nth-child(1) {
        padding-right: 8%;
        width: 35%;
        max-width: 450px;
        min-width: 300px;
      }

      &:nth-child(2) {
        width: 65%;
      }
    }

    .theme-left-column-wider > & {
      &:nth-child(1) {
        padding-right: 8%;
        width: 65%;
      }

      &:nth-child(2) {
        width: 35%;
        max-width: 450px;
        min-width: 300px;
      }
    }

    .theme-left-column-super-wide > & {
      &:nth-child(1) {
        padding-right: 4%;
        width: 75%;
      }

      &:nth-child(2) {
        width: 25%;
        max-width: 450px;
        min-width: 300px;
      }
    }
  }
}
