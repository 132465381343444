.stores-list {
  display: block;
  border-collapse: collapse;
  width: 100%;
  line-height: 1.2;

  @include breakpoint-l {
    display: table;
  }

  tbody {
    display: block;

    @include breakpoint-l {
      display: table-row-group;
    }
  }

  thead {
    display: none;

    @include breakpoint-l {
      display: table-header-group;
    }
  }

  tr {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: $spacing-l 0;
    border-bottom: 1px solid $border-gray;

    @include breakpoint-l {
      display: table-row;
      vertical-align: top;
    }
  }

  th {
    display: none;
    color: $medium-gray;
    font-weight: 300;
    text-align: left;
    padding-bottom: $spacing-m;

    @include breakpoint-l {
      display: table-cell;
    }
  }

  td {
    display: block;

    @include breakpoint-l {
      display: table-cell;
      padding: ($spacing-l + $spacing-s) $spacing-s $spacing-l 0;
    }
  }
}

.stores-list-name {
  width: 100%;
  margin-bottom: $spacing-m;

  @include breakpoint-s {
    width: 50%;
    margin-bottom: $spacing-l;
  }

  @include breakpoint-l {
    width: auto;
  }
}

.stores-list-address {
  width: 100%;
  margin-bottom: $spacing-m;

  @include breakpoint-s {
    width: 50%;
    text-align: right;
    padding-right: $spacing-xl;
    margin-bottom: 0;
  }

  @include breakpoint-l {
    width: auto;
    text-align: left;
    padding-right: $spacing-s;
  }
}

td.stores-list-hours {
  width: 100%;
  margin-bottom: $spacing-l;

  p {
    margin: 0;
  }

  @include breakpoint-s {
    width: auto;
    margin-bottom: 0;
  }
}

.stores-list-phone {
  @include breakpoint-s {
    margin-left: auto;
    padding-right: $spacing-l;
  }

  @include breakpoint-l {
    padding-right: $spacing-s;
  }

  a {
    white-space: nowrap;
  }
}

.stores-list-email {
  margin-left: auto;

  @include breakpoint-s {
    margin-left: 0;
  }
}

.stores-list-icon {
  position: absolute;
  top: $spacing-l;
  right: 0;

  @include breakpoint-l {
    position: static;
  }

  span {
    @include visually-hidden;
  }
}
