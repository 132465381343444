.magazine-related-pages {

  &__title {
    text-align: center;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @include breakpoint-m {
      &:after {
        content: '';
        flex: calc((100% - 20px)/3);
        max-width: calc((100% - 20px)/3);
      }
    }

    &-item {
      width: 100%;
      margin-bottom: 40px;

      @include breakpoint-m {
        flex: calc((100% - 20px)/3);
        max-width: calc((100% - 20px)/3);
      }
    }
  }
}