.order-summary-wrapper {
  background-color: $light-gray;
  padding: $spacing-l 0 $spacing-xxl 0;

  .order-summary {
    background: white;
    border-radius: $spacing-s;
    padding: $spacing-xl;
  }

  .cart {
    margin: 0;
  }
}

.order-confirmation-heading,
.order-confirmation-description {
  text-align: center;
}

.order-summary-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  @include breakpoint-m {
    flex-direction: row;
    justify-content: space-between;
  }

  h2 {
  }

  a {
    margin-top: 0;

    @include breakpoint-m {
      margin-top: $spacing-l;
    }
  }
}

.order-summary-info {
  @include default-table;

  tr {
    @include breakpoint-l {
      border-bottom: 1px solid $border-gray;
    }
  }

  td {
    span {
      &:first-child:not(:only-child) {
        @include breakpoint-l {
          display: none;
        }
      }
    }
  }

  th,
  td {
    &:last-child {
      text-align: left;
    }

    border-bottom: 0;

    @include breakpoint-l-max {
      span {
        width: 50%;
      }
    }
  }

  tbody {
    tr {
      @include breakpoint-l {
        border-bottom: 0;
      }
    }
  }
}

.order-summary-reorder-button {
  svg {
    margin-left: auto;
  }
}

.order-summary-print-button {
  margin-top: $spacing-l;
  align-self: flex-start;

  @include breakpoint-m-max {
    display: none;
  }
}

.order-summary-button-wrapper {
  max-width: 520px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.order-summary-previous-link {
  padding-bottom: $spacing-xl;
  background-color: $light-gray;
}
