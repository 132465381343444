.checkout-page {
  background-color: $light-gray;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: $spacing-xxl;
}

.checkout-step {
  background: white;
  padding: $spacing-l;
  margin: $spacing-l 0;
  overflow: hidden;
  will-change: transform;

  @include breakpoint-m {
    padding: $spacing-xl;
  }
}

.checkout-step-header {
  position: relative;
  cursor: default;

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .checkout-step:not(.is-enabled) & {
    h2 {
      color: $medium-gray;
    }
  }

  h2 {
    display: inline-block;
    margin: $spacing-m;
    margin-left: $spacing-xl + $spacing-l;
    border-bottom: 1px solid transparent;
    line-height: 1.2;
  }

  .checkout-step.is-completed & h2 {
    color: $dark-cyan;
    border-bottom: 1px solid $dark-cyan;
  }
}

.checkout-step-number,
.checkout-step-checkmark {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: white;
  border: 3px solid currentColor;

  &.before-enter,
  &.leaving {
    opacity: 0;
    transform: scale(0.5);
  }

  &.entering {
    opacity: 1;
    transform: none;
    transition: transform 0.5s $ease-out, opacity 0.3s;
  }

  &.before-leave {
    transition: transform 0.4s $ease-out, opacity 0.2s 0.2s;
  }
}

.checkout-step-checkmark {
  width: 40px;
  height: 40px;
  margin: 0;
  margin-top: -20px;
  color: $dark-cyan;

  @include breakpoint-m {
    width: 54px;
    height: 54px;
    margin-top: -27px;
  }
}

.checkout-step-number {
  width: 40px;
  height: 40px;
  margin: 0;
  margin-top: -20px;
  border-radius: 50%;
  color: $medium-gray;

  .is-enabled & {
    color: $orange;
  }

  @include breakpoint-m {
    width: 58px;
    height: 58px;
    margin-top: -29px;
  }

  span {
    font-weight: bold;
    margin-top: $spacing-s;
  }
}

.checkout-step-header > h2 {
  @include breakpoint-m {
    margin-left: 110px;
  }
}

.checkout-step.theme-padding {
  .checkout-step-content {
    @include breakpoint-l {
      margin-left: 110px;
    }
  }
}

.checkout-step-info {
  display: none;
  color: $dark-cyan;

  @include breakpoint-m {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.checkout-step-summary {
  margin-right: $spacing-l;
  font-weight: bold;
  line-height: 1.2;
}

.checkout-step-content {
  padding-top: $spacing-xl;

  .radio-input {
    margin-bottom: $spacing-l;

    &.last-child {
      margin-bottom: 0;
    }
  }
}

.checkout-step:not(.is-enabled) .checkout-step-content-non-js {
  @include non-js-reveal();
}

.checkout-consents {
  margin: $spacing-xl 0;

  @include breakpoint-l {
    margin: $spacing-xl 110px;
  }

  span {
    display: block;

    &:first-child {
      font-weight: normal;
    }

    span:last-child {
      font-weight: light;
    }
  }
}
