.checkout-logout-content {
  background-color: $light-cyan;
  padding: $spacing-l $spacing-xl;
  margin-bottom: $spacing-l;

  @include breakpoint-m {
    display: inline-flex;
    align-items: center;
  }

  p {
    margin: 0;
    margin-right: $spacing-l;
  }
}

.checkout-logout-button {
  width: 100%;
  margin-top: $spacing-l;

  @include breakpoint-m {
    margin-left: $spacing-xl;
    width: auto;
    margin-top: 0;
  }
}
