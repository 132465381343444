.cart-discount-form {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 0;
  margin-left: auto;
  margin-top: -1px;
  border-top: 2px solid $border-gray;

  .theme-maxi & {
    @include breakpoint-m {
      max-width: 520px;
    }
  }

  &__container {
    width: 100%;
    #{$self}--hidden &{
      display: none;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 30px 0 0;
  }

  &__input-column {
    width: 100%;
  }

  &__button {
    white-space: nowrap;
    align-self: flex-start;
    height: 50px;
    margin-left: 30px;

    @include breakpoint-m {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  &__input {
    align-self: flex-start;
  }
}
