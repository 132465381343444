.input-table {
  width: 100%;

  thead {
    th {
      padding: 0 $spacing-s $spacing-m;
    }
  }

  tbody {
    th {
      width: 20%;
    }
  }

  th {
    text-align: left;
    font-weight: normal;
  }

  td {
    width: 40%;
    padding: $spacing-s;
  }
}
