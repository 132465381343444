.login-modal {
  padding: $spacing-xl 0;

  @include breakpoint-m {
    padding: $spacing-xl + $spacing-l;
    padding-top: $spacing-xl;
  }

  h2 {
    margin: 0;
  }
}

.login-modal-link-wrapper {
  margin-top: $spacing-xl;

  p {
    margin: 0;
  }
}

.login-modal-button {
  width: 100%;
  margin-top: $spacing-m;
}

.login-modal-register {
  margin-top: $spacing-xl;

  p {
    margin: 0;
  }
}

.login-modal-register-link {
  margin-top: $spacing-m;
}
