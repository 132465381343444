$button-size: 30px;
$tooltip-bg-color: black;

.info-note {
  $self: &;
  z-index: 1;
  width: $button-size;
  height: $button-size;
  padding: 0;
  position: relative;
  display: inline-flex;

  &__button {
    padding: 0;
    border: none;
    border-radius: 50%;
    height: $button-size;
    width: $button-size;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
    display: block;
    text-align: center;
    font-size: convert-to-rem(16);
    font-weight: bold;
    border: 1px solid black;
    background-color: white;

    &::before {
      content: "i";
    }

    &:hover,
    &:focus-visible,
    &--active {
      background-color: black;
      color: white;
    }
  }

  &__text {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(#{$button-size} + 7px);
    padding: 10px;
    width: max-content;
    max-width: 200px;
    z-index: 2;
    box-sizing: border-box;
    background-color: $tooltip-bg-color;
    color: white;
    text-align: center;
    border-radius: 5px;
    word-break: break-word;

    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      border-color: transparent $tooltip-bg-color transparent transparent;
      content: "";
      position: absolute;
      right: 100%;
      top: calc(50% - 5px);
    }

    &--position-left {
      left: auto;
      right: calc(#{$button-size} + 7px);

      &::after {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent $tooltip-bg-color;
        right: auto;
        left: 100%;
      }
    }

    &--is-shown {
      display: block;
    }
  }
}