.spinner {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  animation: spinner-spin 1s linear infinite;
  color: $dark-cyan;

  &.theme-big {
    height: 50px;
    width: 50px;

    @include breakpoint-m {
      height: 100px;
      width: 100px;
    }
  }

  &.theme-white {
    color: white;
  }
}

.spinner-icon {
  width: 100%;
  height: auto;
}

@keyframes spinner-spin {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}
