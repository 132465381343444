.inspirational-item {
  $self: &;

  &--left,
  &--right {
    #{$self}__content {
      @include breakpoint-m {
        flex-direction: row;
      }
    }

    #{$self}__image {
      @include breakpoint-m {
        flex: 0 0 50%;
        margin-bottom: 0;
      }
    }
  }

  &--right {
    #{$self}__image {
      order: 1;
    }

    #{$self}__text {
      order: 2;
    }
  }

  &--left {
    #{$self}__image {
      @include breakpoint-m {
        order: 2;
      }
    }

    #{$self}__text {
      @include breakpoint-m {
        order: 1;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__image {
    margin-bottom: $spacing-l;
  }

  &__image-credit {
    @include image-credit-text;
  }

  &__image-container {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (3 / 4) * 100%;
    }
  }

  &__text {
    padding: 0 $spacing-m;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text-content {
    text-align: center;

    @include breakpoint-m {
      max-width: 75%;
    }
  }

  &__title {}

  &__lead {
    margin-top: $spacing-m;

    #{$self}--in-magazine & {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__link {
    margin-top: $spacing-l;
  }

  &__category {
    &--over-image {
      @include breakpoint-m {
        #{$self}--left &,
        #{$self}--right & {
          display: none;
        }
      }
    }

    &--above-title {
      display: none;

      #{$self}--left &,
      #{$self}--right & {
        display: none;

        @include breakpoint-m {
          position: static;
          top: 0;
          left: 0;
          display: inline-block;
          margin-bottom: $spacing-l;
        }
      }
    }
  }
}