.main-title {
  position: relative;
  text-align: center;
  border-bottom: 1px solid $border-gray;
  padding-bottom: $spacing-l;
  margin: $spacing-xl 0 30px;

  @include breakpoint-m {
    padding: 0 15% $spacing-l;
  }

  &.theme-small {
    @include breakpoint-s-max {
      margin: $spacing-l 0 0;

      .main-title-title {
        font-size: 28px;
      }

      .main-title-pre-title {
        font-size: 16px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -30px;
    height: 4px;
    width: 60px;
    background-color: $orange;
  }

  > * {
    display: block;
  }
}

.main-title-pre-title {
  color: $medium-gray;
  margin-bottom: $spacing-m;
}

.main-title-h1 {
  margin: 0;
}
