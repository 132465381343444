.modal {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0s 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, transform 0s 0.5s, visibility 0s 0.5s;

  &.is-visible {
    opacity: 1;
    transition-delay: 0s;
    visibility: visible;
    transform: none;
  }
}

.modal-background {
  position: absolute;
  width: 100vw;
  bottom: 0;
  top: 0;
  left: calc(50% - 50vw);
  background-color: rgba(black, 0.4);
}

.modal-content {
  position: relative;
  overflow: hidden;
  will-change: transform;
  overflow: hidden;

  .animation-enabled & {
    transition: transform 0.5s cubic-bezier(0.3, 0.75, 0.3, 1);
    transform: translateY(-100px);
  }

  .modal.is-visible & {
    transform: none;
  }
}

.modal-close {
  position: absolute;
  top: $spacing-l;
  right: $spacing-l;
  cursor: pointer;
}

.modal-label {
  position: absolute;
  top: 46px;
  left: 29px;
  z-index: 1;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  cursor: pointer;
}

.modal-close-text {
  top: 46px;
  right: 29px;
  z-index: 1;
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  text-transform: uppercase;
  cursor: pointer;
}
