.lens-visualisation-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.lens-visualisation {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    display: block;
  }
}

.lens-visualisation-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  mix-blend-mode: multiply;
  will-change: transform;

  &-gray-black {
    background-color: $medium-gray;
  }

  &-brown {
    background-color: #68380a;
  }

  &-green {
    background-color: #37ac59;
  }
}

.lens-visualisation-slider-wrapper {
  position: absolute;
  height: 100%;
  left: -50%;
  top: 0;
  width: 100%;
  will-change: transform;
}

.lens-visualisation-slider {
  position: absolute;
  top: 0;
  right: -25px;
  bottom: 0;
  height: 100%;
  width: 50px;
  color: black;

  &:hover {
    .slider-square {
      transform: scale(1.15);

      svg {
        transform: scale(1.15);
      }
    }

    .slider-arrow-left {
      transform: translateX(-3px) scaleX(-1);
    }

    .slider-arrow-right {
      transform: translateX(3px);
    }
  }

  @include breakpoint-s {
    right: -40px;
    width: 80px;
  }

  .slider-arrow-group {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    svg {
      margin: $spacing-s;
      width: 6px;

      transition: transform 0.4s $ease-out;

      @include breakpoint-s {
        width: 10px;
      }
    }
  }

  .slider-line-top,
  .slider-line-bottom {
    position: absolute;
    right: 50%;
    height: calc(50% - 20px);
    border-left: 1px solid black;

    @include breakpoint-s {
      height: calc(50% - 30px);
      border-left-width: 2px;
    }
  }

  .slider-line-top {
    top: 0;
  }

  .slider-line-bottom {
    bottom: 0;
  }

  .slider-arrow-left {
    transform: scaleX(-1);
  }

  .slider-knob {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    flex-shrink: 0;
  }
}
