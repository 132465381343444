.appointment-booking-time-list {
  padding-top: 40px;
  padding-bottom: 80px;

  @include breakpoint-im {
    max-width: 384px;
    margin: 0 auto;
    padding-bottom: 0;
  }

  &__title {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin: 0 0 10px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
  }
}
