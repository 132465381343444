.cart-discount-delete-form {
  $self: &;
  //@include font-size-sm;
  font-weight: bold;
  line-height: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;

  &__form-wrapper{
    padding-right: 20px;
  }

  &__text-wrapper{
    align-self: center;
    text-align: left;
  }

  &__button[type="submit"] {
    cursor: pointer;
  }
}
