.checkbox {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &.theme-grid {
    width: 100%;
    margin-top: $spacing-l;

    @include breakpoint-m {
      margin-left: $spacing-m;
    }
  }

  &.theme-align-top {
    label {
      align-items: flex-start;
    }
  }

  &.theme-link {
    label span {
      transform-origin: 50% 100%;
      transition: transform 0.5s $ease-out;
      will-change: transform;
    }

    input:checked ~ label span,
    input:not(:disabled) ~ label:hover span {
      color: $dark-cyan;
      text-decoration: underline;
    }
  }

  &.theme-hidden-checkbox {
    .checkbox-fake {
      display: none;
    }
  }

  input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    margin: 0;
    opacity: 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  input[disabled] ~ label {
    color: $medium-gray;
  }
}

.checkbox-description {
  margin-top: $spacing-m;

  @include breakpoint-m {
    margin-left: 30px;

    &-text{
    display: inline;
    }
    
    &-tooltip{
      margin-left: 10px;
    }
  }
}

.checkbox-fake {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  margin-right: $spacing-m;
  border: 1px solid $medium-gray;
  background-color: white;

  @include breakpoint-m {
    height: 20px;
    width: 20px;
  }

  input[disabled] ~ label & {
    opacity: 0.35;
  }

  input:checked ~ label & .checkbox-checkmark {
    transform: none;
  }

  html:not(.mouse-user) input:focus ~ label & {
    @include focus-outline;
  }
}

.checkbox-checkmark {
  position: absolute;
  top: 50%;
  left: 9px;
  margin-top: -5px;
  transform: scale(0);
  transition: transform 0.2s $ease-out;

  @include breakpoint-m {
    left: 3px;
  }
}

.checkbox-error {
  color: $orange;
  padding-left: 30px;
  margin-top: $spacing-s;
}
