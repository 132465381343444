.select {
  position: relative;
  min-width: 100px;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.has-error {
    .select-element {
      border-color: $orange;
    }
  }

  &.is-active {
    z-index: 2;
  }

  &.theme-simple {
    .select-element {
      display: block;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    .select-fake-value {
      text-decoration: underline;
    }
  }

  select {
    // NOTE: Makes the select element overlap the entire fake select to be accessible with touch screens
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 16px; // NOTE: Prevent iPhone zoom on focus
    animation: fade-in 0s 1s both; // NOTE: Prevents flash of native select element before mount
  }

  &.is-mounted {
    // NOTE: Makes real select invisible and puts it behind fake select
    select {
      z-index: 0;
      opacity: 0;
      animation-fill-mode: none;
    }
  }

  // &.has-touch {
  //   // NOTE: Puts the invisible real select on top of the fake select to bring up the native select menu on touch
  //   select {
  //     z-index: 2;
  //   }
  // }

  &.is-disabled {
    opacity: 0.5;
  }

  &-label {
    display: block;
    margin-bottom: $spacing-s;
  }

  &-label-link {
    margin-left: $spacing-s;

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }
  }

  &-fake {
    position: relative;
    user-select: none;

    // NOTE: Outline for keyboard users
    .select:not(.select--has-touch) select:focus + & {
      outline: 1px dotted currentColor;
      outline: 4px solid black;
    }
  }

  &-element,
  &-single {
    background-color: $light-cyan;
    padding: 17px 15px;
    border: 1px solid black;
    line-height: 1;
    cursor: pointer;
  }

  &-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-single {
    border-color: #bfd6d6;
  }

  &-dropdown {
    list-style: none;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    background-color: $light-cyan;
    border-top: none;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    max-height: 200px;
    overflow: auto;
    border: 1px solid black;
    border-top: none;
  }

  &-option {
    cursor: pointer;
    padding: 10px 20px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }

    &:hover {
      background-color: white;
      color: black;
    }

    &.is-selected {
      background-color: white;
    }
  }

  &-icon {
    color: currentColor;
    margin-left: $spacing-s;

    &.is-active {
      transform: rotate(-180deg);
    }
  }
}

.select-fake-label {
  color: $medium-dark-gray;

  &:after {
    content: ':';
    margin-right: $spacing-s;
  }
}

.select-error-message {
  color: $orange;
}
