.order-list {
  @include cf;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    text-align: left;
    padding: $spacing-l $spacing-s $spacing-m 0;
    color: $medium-dark-gray;
  }

  tr {
    vertical-align: top;
    border-bottom: 1px solid $border-gray;
  }

  th,
  td {
    &:last-child {
      text-align: right;
      padding-right: 0;
    }

    &.empty {
      display: none;
    }
  }

  td {
    padding: $spacing-l $spacing-s $spacing-l 0;
  }
}

.order-list-item {
  display: flex;
  flex-direction: column;

  @include breakpoint-l {
    flex-direction: row;
    justify-content: space-between;
  }
}

.order-list-details {
  display: flex;
  flex-wrap: wrap;
}

.order-list-details-item {
  margin-top: $spacing-m;
  margin-right: $spacing-l;
  color: $medium-dark-gray;
}

.order-list-button-container {
  max-width: 350px;
  margin-top: $spacing-xl;
  float: right;
}
