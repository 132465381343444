.my-page-sidebar {
  @include breakpoint-l {
    max-width: 350px;
  }
}

.my-page-sidebar-section {
  margin-top: $spacing-xl + $spacing-l;

  &:first-child {
    margin-top: 0;
  }

  h2 {
    margin: 0 0 $spacing-l;
  }

  p {
    margin: 0 0 $spacing-l;
  }
}

.my-page-sidebar-section-button {
  margin-top: $spacing-l;
}
