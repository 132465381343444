.select-sorted-on-geolocation {

  .select {
    color: $dark-gray;
  }

  .select-option {
    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }
  }
}
