.appointment-booking-result-region {
  position: relative;
  padding: 9px 45px 10px 41px;
  border: 1px solid #007a7d;
  border-radius: 2px;
  cursor: pointer;

  @include breakpoint-im {
    padding: 17px 60px 18px 50px;
  }

  &__pin-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    color: #007a7d;
    padding: 0 12px;

    @include breakpoint-im {
      width: 50px;
    }
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #2c2c2c;
  }

  &__edit-box {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    display: flex;
    width: 45px;
    justify-content: center;
    align-items: center;
    color: #979797;
    padding: 0 10px 0 14px;
    border-left: 1px solid #bfdfe0;

    @include breakpoint-im {
      width: 60px;
      padding: 0 19px 0 20px;
    }

    & > * {
      width: 100%;
      transition: all .25s ease;
    }
  }

  &:hover .appointment-booking-result-region__edit-box {
    color: #2c2c2c;
  }
}
