.consent-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: $spacing-xl;

  @include breakpoint-m {
    justify-content: flex-start;
  }

  .consent-button {
    width: calc(50% - 5px);

    @include breakpoint-m {
      min-width: 150px;
      width: auto;
      margin-right: $spacing-l;
    }
  }
}

.consent-checkbox-group {
  margin-top: $spacing-l;
}

.consent-checkbox-wrapper {
  margin-bottom: $spacing-l;
  padding-left: $spacing-m;
}

.consent-checkbox-label,
.consent-checkbox-description {
  display: block;
}

.consent-checkbox-label {
  font-weight: normal;
  line-height: 1;
  color: black;
}

.consent-checkbox-description {
  margin-top: $spacing-s;
  color: $medium-dark-gray;
}
