.store-body {
  @include breakpoint-m {
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.store-body-lead {
  margin-top: $spacing-xl;
}

.store-body-text {
  margin: $spacing-xl 0;
}
