.carousel {
  padding-bottom: 60px;
  position: relative;

  &.theme-block {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }

  &.has-thumbnails {
    padding-bottom: 0;
  }

  &.theme-cta {
    .carousel-next,
    .carousel-prev {
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.4);
    }
  }
}

.carousel-live-region {
  @include visually-hidden;
}

.carousel-content {
  position: relative;
}

.carousel-nav {
  display: flex;
  justify-content: space-between;

  @include breakpoint-m {
    padding: 0 1rem;
    width: 100%;
  }
}

.carousel-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  .carousel.theme-block &,
  .carousel.theme-cta &,
  .carousel.has-thumbnails & {
    > li {
      padding: 0;
    }
  }

  > li {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 $spacing-xl;

    .carousel.theme-cta & {
      justify-content: center;
    }

    .shows-multiple-slides & {
      padding: 0 $spacing-m;

      @include breakpoint-m {
        padding: 0 $spacing-m;
      }
    }

    > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.carousel-dot-text {
  @include visually-hidden;
}

.carousel-items-wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: $spacing-m;

  @include breakpoint-m {
    margin-bottom: 0;
  }
}

.carousel-items-wrapper {
  .shows-multiple-slides & {
    padding: 0 $spacing-m;

    @include breakpoint-m {
      padding: 0 $spacing-m;
    }
  }
}

.carousel-items {
  will-change: transform;
}

.carousel-next,
.carousel-prev {
  @include remove-button-styles;
  z-index: 1;

  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 100%;

  @include breakpoint-m {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 56px;
    width: 56px;

    svg {
      height: 20px;
      width: auto;
    }
  }

  &:not(:disabled):hover {
    color: $orange;
  }

  &:disabled {
    opacity: 0.1;
  }

  .carousel.theme-small & {
    svg {
      width: 7px;
      height: 13px;
    }
  }

  .carousel.theme-block & {
    top: 60px;
    box-shadow: 0 0 40px rgba(black, 0.2);

    @include breakpoint-s {
      top: 40%;
    }

    @include breakpoint-m {
      top: 50%;
    }
  }
}

.carousel-prev {
  svg {
    transform: scaleX(-1);
  }

  .carousel.theme-cta & {
    left: $spacing-m;
  }

  .carousel.theme-block & {
    left: $spacing-l;

    @include breakpoint-l {
      left: $spacing-xl;
    }

    @include breakpoint-xxl {
      left: calc(50vw - #{$breakpoint-xxl / 2} + #{$spacing-xl});
    }
  }
}

.carousel-next {
  right: 0;

  .carousel.theme-cta & {
    right: $spacing-m;
  }

  .carousel.theme-block & {
    right: $spacing-l;

    @include breakpoint-l {
      right: $spacing-xl;
    }

    @include breakpoint-xxl {
      right: calc(50vw - #{$breakpoint-xxl / 2} + #{$spacing-xl});
    }
  }
}

.carousel-dots {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint-m {
    margin-top: $spacing-l;
  }
}

.carousel-assistive-button {
  @include remove-button-styles;
  color: white;
  font-size: 1rem;
}

.carousel-dot {
  @include remove-button-styles;
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 $spacing-s;
  color: rgba(black, 0.3);
  border: 2px solid currentColor;
  border-radius: 100%;

  &:not(.is-active) {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    border-color: black;
  }

  &.is-active {
    background-color: currentColor;
    color: rgba(black, 1);
  }
}

.carousel-icon {
  width: 28px;
  height: 28px;
}

.makeshift-pause-icon {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid black;
}

.carousel-status {
  @include remove-button-styles;
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 $spacing-s 0 0;
  position: relative;

  .carousel-pause-icon,
  .carousel-stop-icon {
    display: none;
  }

  .carousel-play-icon {
    display: block;
  }

  &--paused {
    .carousel-play-icon,
    .carousel-stop-icon {
      display: none;
    }

    .carousel-pause-icon {
      display: block;
    }
  }

  &--stopped {
    .carousel-play-icon,
    .carousel-pause-icon {
      display: none;
    }

    .carousel-stop-icon {
      display: block;
    }
  }
}

.carousel-status-icon {
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 20px;
  line-height: 0;
  height: 100%;
  width: 100%;
}

.carousel-play-icon {
  top: -3px;
  left: -2px;
}

.carousel-pause-icon,
.carousel-stop-icon {
  position: absolute;
  left: 9px;
  top: 3px;
}

.carousel-thumbnails {
  @include remove-list-styles;
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-xl;

  > li {
    margin-right: $spacing-s;
    margin-bottom: $spacing-s;

    img {
      max-height: 100%;
    }
  }
}

.carousel-thumbnail {
  @include remove-button-styles;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-s;
  width: 60px;
  height: 45px;
  border: 1px solid $medium-gray;
  opacity: 0.5;

  &.is-active {
    opacity: 1;
    border-color: black;
    box-shadow: inset 0 0 0 1px black;
  }
}
