.product-list {
  &.size-two-columns {
    > ul > li {
      @include breakpoint-s {
        width: calc(50% - #{$spacing-m});
      }
    }
  }

  &.size-one-column {
    > ul > li {
      width: 100%;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  > ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    margin-right: -$spacing-m;

    > li {
      display: flex;
      width: 100%;
      margin-bottom: $spacing-m;
      margin-right: $spacing-m;
      flex-grow: 0;

      @include breakpoint-m {
        width: calc(50% - #{$spacing-m});
      }

      @include breakpoint-l {
        width: calc(33.333% - #{$spacing-m});
      }

      @include breakpoint-xl {
        width: calc(25% - #{$spacing-m});
      }
    }
  }

  &.theme-product-backgrounds {
    > ul {
      @include breakpoint-s {
        margin-left: 0;
        margin-right: 0;
      }

      > li {
        @include breakpoint-s {
          width: calc(50% - #{$spacing-s * 2});
          margin: 0 $spacing-s;
        }

        @include breakpoint-m {
          width: calc(33.3% - #{$spacing-s * 2});
          margin: 0 $spacing-s;
        }
      }
    }
  }

  &.theme-narrow {
    padding: 0;

    > ul {
      > li {
        width: 100%;

        @include breakpoint-s {
          width: calc(50% - #{$spacing-m});
        }

        @include breakpoint-l {
          width: calc(33.3% - #{$spacing-m});
        }
      }
    }
  }

  &.theme-no-margin {
    margin: 0 !important;
  }

  &.theme-vertical {
    padding: 0 !important;

    > ul {
      display: block;
      margin-left: 0;

      > li {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
