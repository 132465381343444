.appointment-booking-time-item {
  width: 25%;
  padding: 0 7px;
  margin: 0 0 10px;

  @include breakpoint-xs {
    width: 20%;
  }

  &__inner {
    display: block;
    height: 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 42px;
    color: #2c2c2c;
    text-align: center;
    border: 1px solid #78b9bb;
    border-radius: 2px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      border-color: #007a7d;
    }
  }
}
